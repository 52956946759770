import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDoubleUp } from "@fortawesome/pro-solid-svg-icons"

const ResourceBackToTop = props => {
    return(
        <div className="search-back-to-top" onClick={() => props.onClick()}>
            <FontAwesomeIcon className="reset-icon" icon={faChevronDoubleUp} />
        </div>
    );
}

export default ResourceBackToTop;