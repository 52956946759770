import React from 'react';
import Api from "../../Api";

import Loading from "../Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";


class ResourceTreeNode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            children: [],
            open: false,
            loading: false,
        }
    }

    getChildNodes = (id) => {
        let open = (this.state.open ? false : true);

        if (open) {
            var state = { open, loading: true }
        } else {
            var state = { open }
        }

        this.setState(state);

        if (id) {
            var query = { isEnabled: true, 'parent': id };
        } else {
            var query = { isEnabled: true, 'exists[parent]': false };
        }

        if (open) {
            Api.resourceTrees(query).then(children =>  { 
                this.setState({ 
                    children, 
                    loading: false, 
                });
            });
        }
    }

    render() {
        return (
            <>
                <div className={"resource-tree-node" + (this.props.item.hasChildren ? ' node-has-children' : '' )} style={{ marginLeft: 25 * (this.props.level - 1) + 'px' }}>
                    <div>
                        {this.props.item.hasChildren === true && 
                            <span className="resource-tree-node-icon" onClick={() => this.getChildNodes(this.props.item.id)}>
                                <FontAwesomeIcon icon={(this.state.open ? faMinus : faPlus )} />
                            </span>
                        }

                        <span 
                            className="resource-tree-node-title" 
                            onClick={() => (this.props.onClick ? this.props.onClick(this.props.item) : false)}
                        >
                            {this.props.item.title}
                        </span>

                        {this.state.loading &&
                            <span className="loading-node">
                                <Loading size="1x" />
                            </span>
                        }
                    </div>
                    
                    <div className="resource-tree-node-desc">{this.props.item.description}</div>
                </div>  

                {this.state.open === true && this.state.loading === false &&
                    <>
                        {this.state.children.map((item, index) => {
                            return <ResourceTreeNode key={index} item={item} level={this.props.level + 1} onClick={this.props.onClick} />
                        })}
                    </>
                }
            </>
        );
    }
}

export default ResourceTreeNode;