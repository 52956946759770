import React from 'react';

class FilterPageCategories extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            active: false
        }
    }

    componentDidMount() {
        const sessionCategoryString = window.sessionStorage.getItem('resourceCategory');
        const sessionCategory = JSON.parse(sessionCategoryString);

        if (sessionCategory) {
            this.setState({ active: sessionCategory.id });
        }
    }

    onCategoryClick = (category) => {
        if (this.props.onCategoryChange) {
            this.props.onCategoryChange(category);
        }
        this.setState({ active: (category ? category.id : 'all') });
    }

    render() {
        return (
            <>
                {this.props.categories !== false && this.props.categories.length > 0 && 
                    <div className="filter-page-content__categories">
                        <ul>
                            <li 
                                key="all" 
                                onClick={() => this.onCategoryClick(false)}
                                className={this.state.active === 'all' ? 'active' : '' }
                            >
                                All Resources
                            </li>
                            {this.props.categories.map(category => (
                                <li 
                                    key={category.id} 
                                    className={this.state.active === category.id ? 'active' : '' } 
                                    onClick={() => this.onCategoryClick(category)}
                                >
                                    {category.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </>
        );
    }
}

export default FilterPageCategories;