import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/pro-solid-svg-icons";
import { faThList} from "@fortawesome/pro-solid-svg-icons";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { Dropdown } from "react-bootstrap";

class ResourcesResultsControls extends React.Component {
    constructor(props) {
        super(props);

        this.orders = {
            'viewCount' : 'Popularity',
            'createdAt' : 'Date Created',
            'title' : 'Alphabetical',
        }

        this.state = {
            order: 'viewCount',
            direction: 'asc',
        }
    }

    onSortChange = (orderKey) => {
        this.setState({ order: orderKey });
        this.props.onSortChange(orderKey);
    }

    onDirectionChange = (direction) => {
        this.setState({ direction });
        this.props.onDirectionChange(direction);
    }

    render() {
        return (
            <div className="resource-filter-page-list-header">
                <div className="filter-page-header__results">
                    Results
                    {this.props.totalResults !== false && 
                        <span className="total-count">Total: {this.props.totalResults}</span>
                    }
                </div>
                <div className="filter-page-header__controls">
                    <div className="filter-page-header__sort">
                        <span>Sort By: </span>
                        <Dropdown>
                            <Dropdown.Toggle>
                                {this.orders[this.state.order]} <FontAwesomeIcon icon={faCaretDown}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {Object.keys(this.orders).map(key => (
                                    <Dropdown.Item key={key} onSelect={() => this.onSortChange(key)}>{this.orders[key]}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="filter-page-header__sort-dir">
                        <span>Direction: </span>
                        <Dropdown>
                            <Dropdown.Toggle>
                                {(this.state.direction === 'asc' ? 'Ascending' : 'Descending' )} 
                                <FontAwesomeIcon icon={faCaretDown}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onSelect={() => this.onDirectionChange('asc')}>Ascending</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.onDirectionChange('desc')}>Descending</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="filter-page-header__view">
                        <span>View: </span>
                        <div onClick={() => this.props.onViewChange('grid')} className={"filter-page-header__view-btn" + (this.props.view === 'grid' ? ' active' : '' )}>
                            <FontAwesomeIcon icon={faTh}/>
                            Grid
                        </div>
                        <div className="pipe"></div>
                        <div onClick={() => this.props.onViewChange('list')} className={"filter-page-header__view-btn" + (this.props.view === 'list' ? ' active' : '' )}>
                            <FontAwesomeIcon icon={faThList}/>
                            List
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResourcesResultsControls;