import React from "react";
import { withRouter, Redirect } from "react-router-dom";

const UserContext = React.createContext(null);

class _LoginOnly extends React.Component {
    static contextType = UserContext;

    render() {
        if (!this.context) {
            if (this.props.location.pathname !== "/log-in") {
                const redirect = encodeURIComponent(this.props.location.pathname);
                return <Redirect to={`/log-in?redirect=${redirect}`} />;
            } else {
                return null;
            }
        }

        return this.props.children;
    }
}

const LoginOnly = withRouter(_LoginOnly);

export { LoginOnly, UserContext };
