import React from 'react';
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import Api from '../Api';
import PopUp from './PopUp';
import '../styles/HomePopUp.scss';

class HomePopUp extends React.Component {
    constructor(props) {
        super(props);

        let url = window.location.href;
        this.isCoursesPage = (url.includes('/courses') ? true : false);

        this.state = {
            modalContent: '',
            show: (this.isCoursesPage ? false : true),
        }

        this.handleClose = () => {
            this.setState({ show: false });
        }
    }

    componentDidMount() {
        if (!window.sessionStorage.getItem('popup') && !this.isCoursesPage) {
            Api.popUps(1, true)
                .then((response) => {
                    if (response) {
                        this.setState({ modalContent: response.content });
                        window.sessionStorage.setItem('popup', true);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
            ;
        }
    }

    render() {
        let modalHeader = (
            <>
                <div className="intro-modal__header-content">
                    {this.props.image && 
                        <div className="intro-modal__logo">
                            <img className="title-page-logo" src={this.props.image} alt="" />
                        </div>
                    }
                    <div className="intro-modal__title">
                        {this.props.title && 
                            <h2 className="text-primary main-title">{this.props.title}</h2>
                        }
                        {this.props.caption && 
                            <p>{this.props.caption}</p>
                        }
                    </div>
                </div>
            </>
        );

        let modalContent = (this.state.modalContent ? <div className="content" dangerouslySetInnerHTML={{__html: this.state.modalContent}}></div> : false);
        return (
            <>
                {modalContent && 
                    <PopUp size="lg" className="intro-modal" header={modalHeader} content={modalContent} open={this.state.show} close={this.handleClose} />
                }
            </>
        );
    }
}

export default HomePopUp;