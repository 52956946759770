import React from 'react';
import { withRouter } from "react-router";
import ResourcesFile from '../Resources/ResourcesFile';
import Api from "../../Api";
import Loading from '../Loading';
import BackToAccount from './BackToAccount';

class Favourites extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            resources: [],
            loading: true,
        }

        this.handleClick = this.handleClick.bind(this);
        this.onFavourite = this.onFavourite.bind(this);
    }

    componentDidMount() {
        this.getFavouriteResources(true);
    }

    getFavouriteResources(hideLoader = false) {
        Api.currentUser()
            .then((response) => {
                this.setState({ resources: response.favourites || [] })

                if (hideLoader) {
                    this.setState({ loading: false })
                }
            })
        ;
    }

    handleClick() {
        this.getFavouriteResources();
    }

    onFavourite(id) {
        Api.userFavourites(id)
            .then((response) => {
                this.props.loginRefresh();
            })
            .catch(err => console.error(err))
        ;
    }

    render () {
        return (
            <div>
                {this.state.loading === true && 
                    <Loading size="2x" />
                }

                {this.state.loading === false && 
                    <>
                        {!this.state.resources.length && 
                            <p>You've not added any favourites.</p>
                        }

                        {this.state.resources && 
                            <>
                                {this.state.resources.map((resource, index) =>
                                    <div key={index} onClick={this.handleClick}>
                                        <ResourcesFile 
                                            key={index} 
                                            index={index} 
                                            resource={resource} 
                                            user={this.props.user} 
                                            isFavourite={true}
                                            onFavourite={this.onFavourite}
                                        />
                                    </div>
                                )}
                            </>
                        }
                    </>
                }

                <BackToAccount />
            </div>
        )
    }
}
    
export default withRouter(Favourites);