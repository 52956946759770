import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";


const FilterPageBtn = props => {
    const className = "filter-page-header-btn" + (props.className ? ' ' + props.className : '');

    return <>
        {props.element === 'a' &&
            <a 
                className={className} 
                href={props.href} 
                target={props.target ? props.target : '' }
            >
                <span>
                    <FontAwesomeIcon icon={props.icon} />
                </span>
                
                {props.title}
            </a>
        }

        {props.element === 'link' &&
            <Link className={className} to={props.to}>
                <span>
                    <FontAwesomeIcon icon={props.icon} />
                </span>
                    
                {props.title}
            </Link>
        }

        {props.element === 'div' &&
            <div 
                className={className} 
                onClick={() => props.onClick()} 
                target={props.target ? props.target : '' }
            >
                <span>
                    <FontAwesomeIcon icon={props.icon} />
                </span>
                
                {props.title}
            </div>
        }
    </>
}

export default FilterPageBtn;