import React from 'react';
import { Col } from "react-bootstrap";

class ResourceTreeNodeNew extends React.Component {
    render() {    
        return(
            <>
                <Col key={`node-${this.props.node.id}`} xl="4" md="6" xs="12">
                    <div className="resource-tree-node">
                        <div>
                            <div className="resource-tree-node__title">
                                {this.props.node.title}
                            </div>
                            <div className="resource-tree-node__desc">
                                {this.props.node.shortDescription}
                            </div>
                        </div>
                        <div className="resource-tree-node__link-wrap">
                            <div className="resource-tree-node__link" onClick={() => this.props.onResourceIndexClick(this.props.node)}>
                                View
                            </div>
                        </div>
                    </div>
                </Col>
            </>
        );
    }
}

export default ResourceTreeNodeNew;