import React from 'react';
import Api from "../../Api";
import Loading from "../Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";

class FilterPageProfileTypes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            profileTypes: false,
            open: [],
            profiles: [],
            profileLoading: false,
            fetched: [],
            selected: {},
            criteriaIds: [],
        }
    }

    componentDidMount() {
        Api.profileTypes(false, this.props.isResourceIndex, this.props.isDataIndex, this.props.isNetworkIndex)
            .then(profileTypes => {
                this.setState({ profileTypes, loading: false });
            })
        ;
    }

    componentDidUpdate(prevProps) {
        this.criteriaIds = [];

        this.props.criteria.forEach((item, index) => {
            this.criteriaIds.push(item.id);
        });

        if (this.props.criteria.length !== prevProps.criteria.length) {
            this.forceUpdate();
        }
    }

    toggleProfileType = (profileTypeId) => {
        let open = [...this.state.open];

        if (open.includes(profileTypeId)) {
            open = open.filter(item => item !== profileTypeId)
        } else {
            open.push(profileTypeId);
        }

        this.setState({ open });

        let fetched = this.state.fetched;

        if (!fetched.includes(profileTypeId)) {
            this.setState({ profileLoading: profileTypeId });

            let profilesSearch = { 'order[title]': 'ASC', perPage: 1000, noRelationships: 1 }
            
            if (this.props.additionalProfileSearch) {
                profilesSearch = { ...profilesSearch, ...this.props.additionalProfileSearch };
            }

            Api.profiles(profilesSearch, true, true, profileTypeId)
                .then(response => {
                    let profiles = this.state.profiles;
                    profiles[profileTypeId] = response;

                    fetched.push(profileTypeId);

                    this.setState({ profiles, fetched, profileLoading: false });
                })
            ;
        }
    }

    onProfileClick = (profile) => {
        if (this.props.selectableIds === false || 
            this.props.selectableIds.length === 0 || 
            this.props.selectableIds.includes(profile.id)
        ) {
            this.props.onProfileClick(profile);
        }
    }

    render() {
        let selected = {};

        this.props.criteria.forEach((item, index) => {
            if (!(item.profileType.id in selected)) {
                selected[item.profileType.id] = [];
            }

            selected[item.profileType.id].push(item.id);
        });

        return (
            <>
                {this.state.profileTypes && this.state.loading === false && 
                    <div className="filter-page-profile-types-wrap">
                        {this.state.profileTypes.map((profileType, index) => (
                            <div key={"pt-" + index} className={"filter-page-profile-type " + (this.state.open.includes(profileType.id) ? 'filter-page-open' : '' ) }>
                                <h4 onClick={() => this.toggleProfileType(profileType.id)}>
                                    {profileType.title}
                                    
                                    <div>
                                        {(profileType.id in selected) && selected[profileType.id].length > 0 &&
                                            <span className="selected-count"><i>{selected[profileType.id].length} selected</i></span>
                                        }

                                        <span className="profile-filter-arrow"><FontAwesomeIcon icon={faChevronUp} /></span>
                                    </div>
                                </h4>

                                <div className="filter-page-profile-wrap">
                                    {this.state.profileLoading === profileType.id && 
                                        <Loading size="2x"/>
                                    }

                                    {this.state.profileLoading !== profileType.id && 
                                    this.state.profiles[profileType.id] && 
                                    this.state.profiles[profileType.id].length > 0 &&
                                        <>
                                            {this.state.profiles[profileType.id].map((profile, index) => {
                                                let className = 'filter-page-profile';

                                                if (this.criteriaIds.includes(profile.id)) className += ' is-criteria';
                                                
                                                if (this.props.selectableIds && 
                                                    this.props.selectableIds.length > 0 && 
                                                    !this.props.selectableIds.includes(profile.id)
                                                ) {
                                                    className += ' is-disabled';
                                                }
                                                
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className={className} 
                                                        onClick={() => this.onProfileClick(profile)}
                                                    >
                                                        <div className="filter-page-profile__checkbox"></div>
                                                        {profile.title}
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </>
        );
    }
}

export default FilterPageProfileTypes;