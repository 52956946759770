import React from 'react';
import ReactDOM from 'react-dom'
import Api from "../Api";
import Loading from './Loading';
import DataTable from './DataTable';
import CoursePopUp from './CoursePopUp';
import CoursesIntroPopUp from './CoursesIntroPopUp';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import FilterPageBtn from './FilterPage/FilterPageBtn';
import { faMapMarkerAlt, faTable } from '@fortawesome/pro-solid-svg-icons';
import CoursesMap from './CoursesMap';

const CourseModalToggle = (props) => {
    return (
        <>
            {props.item.courseOutlineSummary}
            <br />
            <a href="#" onClick={props.onClick}>More Information</a>
        </>
    );
}

class Courses extends React.Component {
    constructor(props) {
        super(props);

        const that = this;

        this.state = {
            loading: true,
            courses: [],
            modalShow: false,
            modalContent: '',
            view: "table",
            filters: {},
            columns: [
                { 
                    title: "Country", 
                    data: "country",
                },
                { 
                    title: "Name", 
                    data: "name",
                    width: '15%',
                },
                { 
                    title: "Institution", 
                    data: "institution", 
                    width: '15%',
                },
                { 
                    title:"Language", 
                    data: "language",
                },
                { 
                    title: "Keywords", 
                    data: "keywords",
                    width: '15%',
                },
                { 
                    title: "Type", 
                    data: "type",
                    width: '15%',
                },
                { 
                    title: "Course Outline", 
                    data: "course_outline",
                    width: '35%',
                    createdCell: (td, cellData, rowData, row, col) => {
                        ReactDOM.render(cellData, td);
                    }
                }
            ],
            searchValue: '',
            options: {
                responsive: false,
                scrollX: true,
                dom: '<"top"f>rt<"bottom"<"paging"il>p><"clear">',
                ordering: false,
                initComplete: function(settings, json) {
                    this.api().columns([0, 3, 5]).every( function () {
                        let column = this;
                        let ddText = column.header();
                        let select = $('<select><option value="">' + ddText.innerHTML + '</option><option value="">-------------------</option></select>');

                        select.appendTo($(column.header()).empty())
                            .on('change', function() {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                column.search(val ? '^'+val+'$' : '', true, false).draw();

                                var label = $.fn.dataTable.util.escapeRegex($(this).find('option:first-of-type').text())
                                that.setState({ filters: { ...that.state.filters, [label.toLowerCase()]: val.replace(/\\/g, "") } });
                            })
                        ;

                        column.data().unique().sort().each(function (d, j) {
                            if (d) {
                                if ((column.search() === '^' + d + '$') || ("" == d)) {
                                    select.append( '<option value="' + d + '" selected="selected">' + d + '</option>');
                                } else {
                                    select.append('<option value="' + d + '">' + d + '</option>');
                                }
                            }
                        });
                    });
                }
            },
            coursesData: [],
        }

        this.coursesTable = React.createRef();
    }

    componentDidMount() {
        Api.courses()
            .then(response => {
                let coursesData = [];

                response.forEach((item, index) => {
                    coursesData.push({
                        country: item.country,
                        name: this.getName(item),
                        institution: item.institutionAddress,
                        language: item.language,
                        keywords: item.keywords,
                        course_outline: <CourseModalToggle item={item} onClick={() => this.showModalPopUp(item)} />,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        modalTitle: item.name,
                        type: item.type
                    });
                });

                this.setState({ coursesData, loading: false });
            })
        ;
    }

    showModalPopUp = (course) => {
        this.setState({
            modalShow: true,
            modalContent: course
        })
    }

    handleModalClose = () => {
        this.setState({
            modalContent: '',
            modalShow: false,
        })
    }

    getName = (item) => {
        let name;

        if (item.link) {
            name = '<a href="' + item.link + '" target="_blank">' + item.name + '</a>'
        } else {
            name = item.name;
        }

        return name;
    }
    
    toggleView = () => {
        this.setState({ view: (this.state.view === 'table' ? 'map' : 'table') });
    }

    render() {
        const {
            columns,
            options,
            searchValue,
            coursesData,
            view,
            filters
        } = this.state;

        return (
            <div className="courses">
                <CoursesIntroPopUp content={this.props.pageContent.coursesPopUpContent} />
                {this.state.loading &&
                    <Loading size="2x" />
                }

                {this.state.loading === false && 
                    <>
                        {this.props.pageContent &&
                            <div dangerouslySetInnerHTML={{ __html: this.props.pageContent.coursesContent }} />
                        }

                        {this.props?.pageContent?.coursesOrangeBoxContent &&
                            <div 
                                className="courses-orange-box" 
                                dangerouslySetInnerHTML={{ __html: this.props.pageContent.coursesOrangeBoxContent }} 
                            />
                        }

                        <div className="courses-display-as d-flex align-items-center">
                            Display as: 
                            <FilterPageBtn 
                                title="Table View"
                                element="div"
                                icon={faTable}
                                className={"ml-2" + (view === 'table' ? " grey" : "" )}
                                onClick={() => this.toggleView()}
                            />
                            <FilterPageBtn 
                                title="Map View"
                                element="div"
                                icon={faMapMarkerAlt}
                                className={"ml-2" + (view === 'map' ? " grey" : "" )}
                                onClick={() => this.toggleView()}
                            />
                        </div>

                        <div className={"table-responsive" + (view === 'map' ? " d-none" : "")}>
                            <DataTable
                                ref={this.dataTableRef}
                                data={coursesData}
                                columns={columns}
                                options={options}
                            />
                        </div>

                        {view === 'map' &&
                            <CoursesMap 
                                courses={coursesData} 
                                showModal={this.showModalPopUp} 
                                filters={this.state.filters} 
                            />
                        } 
                    </>
                }
                <CoursePopUp content={this.state.modalContent} show={this.state.modalShow} close={this.handleModalClose} />
            </div>
        );
    }
}

export default Courses;