import React, { useEffect } from 'react';

const CoursesMap = ({ courses, showModal, filters }) => {
    useEffect(() => {
        createMap();
    }, []);

    const createMap = () => {
        window.L.mapquest.key = 'jdUHXHS5pGJyUakR5iAZtbdGS3PiCivO';
        var baseLayer = window.L.mapquest.tileLayer('map');

        const map = window.L.mapquest.map('map', {
            center: [51.0915595, -1.7861226],
            layers: baseLayer,
            zoom: 2
        });

        courses = courses.filter(course => {
            let match = true;

            Object.keys(filters).forEach(key => {
                const value = filters[key];

                if (course[key] != value) {
                    match = false;
                }
            });

            return match;
        });

        courses.forEach((course => {
            if (course.latitude && course.longitude) {
                const latLng = new window.L.LatLng(course.latitude, course.longitude);

                window.L.marker(latLng, {
                    icon: window.L.mapquest.icons.marker({})
                }).addTo(map).on('click', () => {
                    showModal(course);
                });
            }
        }));

        // map.addLayer(markers);
    }

    return <div className="courses-map">
        <div id="map"></div>              
    </div>;
}

export default CoursesMap;