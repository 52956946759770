import React, { useState } from 'react';
import PopUp from './PopUp';
import '../styles/HomePopUp.scss';
import LoginForm from './LoginForm';

const LoginPopUp = props => {
    const [show, setShow] = useState(false);

    return <>
        <button className="btn btn-primary" onClick={() => setShow(true)}>Login</button>

        <PopUp 
            size="md" 
            className="login-modal" 
            content={<LoginForm loginRefresh={props.loginRefresh} hideRegister={true} />} 
            open={show} 
            close={() => setShow(false)} 
        />
    </>
}

export default LoginPopUp;