import React from 'react';
import Api from "../../Api";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";

const SAVED_SEARCH_URLS = {
    resource: 'resources',
    data: 'data',
    search: 'search',
    network: 'network'
}

class SavedSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profiles: []
        }
    }

    componentDidMount() {
        let searchIds = JSON.parse(this.props.search.relationshipIds);
        searchIds = searchIds.filter(id => id != null);

        const ids = [];
        const profiles = [];

        searchIds.forEach(id => {
            if (!parseInt(id)) {
                profiles.push({ title: "Resource title contains: " + id });
            } else {
                ids.push(id);
            }
        });

        this.setState({ profiles });

        if (ids.length > 0) {
            Api.profiles({ id: ids, noRelationships: 1 })
                .then(response => {
                    this.setState({ profiles: response.concat(profiles) });
                })
            ;
        }
    }

    getUrl = () => {
        const ids = JSON.parse(this.props.search.relationshipIds);
        const url = (this.props.isAdmin) ? 
            '/resources?search=' + this.props.search.accessUrl : 
            '/' + SAVED_SEARCH_URLS[this.props.search.type] + '?ids=' + ids.join()
        ;

        return url;
    }
     
    render() {
        return (
            <Col key={`search-${this.props.index}`} className="p-2" xl="12" xs="12">
                {this.props.isAdmin === true && <div className="mb-1">{this.getUrl()}</div>}
                <div className="resource resource--search mb-1 p-3">
                    <div className="resource-search-wrap">
                        <Link className="stretched-link" to={this.getUrl()}>
                            {this.state.profiles.map((item, index) => (
                                <span key={index} className="py-2 px-3 m-1">
                                    {item.title}
                                </span>
                            ))}
                        </Link>
                        <div className={'resource__user-favourite'} onClick={() => this.props.onRemove(this.props.search.id)}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </div>
                    </div>
                    <div className="resource-search__timestamp">Saved at: {this.props.search.savedAt}</div>
                </div>
            </Col>
        );
    }
}

export default SavedSearch;