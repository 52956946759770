import React, { Component } from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Api from "../Api";

import parseSearch from "../parseSearch";

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            success: null,
            errorMessage: '',
            loading: false,
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (thisForm.checkValidity() !== false) {
            this.setState({ loading: true })

            Api.login(this.state.username, this.state.password)
                .then(() => {
                    this.props.loginRefresh()
                        .then(() => {
                            this.setState({ success: true, loading: false });
                        })
                    ;
                })
                .catch(err => {
                    this.setState({ success: false, errorMessage: err.message, loading: false });
                })
            ;
        }
    }

    render() {
        if (this.state.success) {
            const search = parseSearch(this.props.location.search);
            if (search.redirect) {
                return <Redirect to={search.redirect} />
            }
        }

        return (
            <>
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>You have been successfully logged in.</p>
                    </Alert>
                } 

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{this.state.errorMessage}</p>
                    </Alert>
                } 

                {this.state.success !== true && 
                    <Form noValidate className="network-create-account-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off">
                        <p>You need to be logged in to view this area, please enter you details below or register for an account.</p>
                        <Form.Group controlId="login-email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control name="username" size="lg" type="text" value={this.state.username} onChange={this.handleInputChange} required />
                        </Form.Group>
                        <Form.Group controlId="login-password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password" size="lg" type="password" value={this.state.password} onChange={this.handleInputChange} required />
                            <Link to="/forgotten-password">I've forgotten my password</Link>
                        </Form.Group>

                        <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                            variant="ternary"
                            size="lg"
                            type="submit"
                            block
                        >
                            {this.state.loading === true && 
                                <FontAwesomeIcon icon={faCircleNotch} size="6x" color="#FF7442" spin />
                            }
                            {this.state.loading === false && 
                                <>Log In</>
                            }
                        </Button>

                        <div>
                            {!this.props.hideRegister && 
                                <Link to="/register">If you do not have an account then click here to register</Link>
                            }
                        </div>                        
                    </Form>
                }
            </>
        );
    }
}

export default withRouter(LoginForm);
