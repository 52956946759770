import React from 'react';

class NetworkLetterList extends React.Component {
    constructor(props) {
        super(props);

        // generate alphabet
        let letters = [];
        for (let i = 0; i < 26; i++) {
            letters.push((i + 10).toString(36));
        }

        this.letters = letters;
    }



    render() {
        return (
            <div className="network-letter-list">
                {this.letters.map(letter => (
                    <div 
                        className={"network-letter-list__letter" + (this.props.activeLetter === letter ? ' is-active' : '' )} 
                        onClick={() => this.props.setLetter(letter)}
                        key={letter}
                    >
                        {letter}
                    </div>
                ))}
            </div>
        );
    }
}

export default NetworkLetterList;