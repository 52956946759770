import fetch from "node-fetch";

class AuthenticationError extends Error {
    constructor(message) {
        super(message);
        this.name = "AuthenticationError";
    }
}

class Api {
    constructor(entryPoint, getToken, setToken, invalidateToken) {
        this.apiUrl = entryPoint;
        this.getToken = getToken;
        this.setToken = setToken;
        this.invalidateToken = invalidateToken;
    }

    search(params, isEnabled = true, isApproved = true, signal = null) {
        return this._makeRequest("/search", {
            query: { ...params, isEnabled, isApproved },
            signal,
        });
    }

    searchV2(params, signal = null) {
        return this._makeRequest("/data-search", {
            query: { ...params },
            signal,
        });
    }

    getSearchCount(relationships) {
        return this._makeRequest("/data/search-count", {
            query: { relationships },
        });
    }

    getMapFilters(profiles) {
        let options = {
            method: "POST",
            body: JSON.stringify({ profiles }),
            headers: { "Content-Type": "application/json" },
        };

        return this._makeRequest("/data/filter-profile-types", options);
    }

    markerPopup(params) {
        return this._makeRequest("/data/get-marker-data", {
            query: { ...params },
        });
    }

    pages(isEnabled = true, isApproved = true) {
        return this._makeRequest("/api/pages", {
            query: { isEnabled, isApproved, pagination: true, perPage: 1000 },
        });
    }

    resourceTrees(query) {
        return this._makeRequest("/api/resource_tree_nodes", { query: query });
    }

    categories(isEnabled = true, isApproved = true, id = null) {
        let url = "/api/resource_categories";

        if (id !== null) {
            url += "/" + id;
        }

        return this._makeRequest(url, {
            query: { isEnabled, isApproved, pagination: false },
        });
    }

    resources(query) {
        return this._makeRequest("/api/resources", { query: { ...query } });
    }

    users(isEnabled = true, isApproved = true, id = null) {
        let url = "/api/users";

        if (id !== null) {
            url += "/" + id;
        }

        return this._makeRequest(url, { query: { isEnabled, isApproved } });
    }

    courses(pagination = false) {
        return this._makeRequest("/api/courses", { query: { pagination } });
    }

    siteOffline() {
        return this._makeRequest("/api/site_offlines/1");
    }

    newsArticles(
        articles = [],
        isEnabled = true,
        isApproved = true,
        orderProp = false,
        orderDir = false,
        pagination = false,
        page = false,
        perPage = false,
        isOnGlobe = null
    ) {
        let query = {
            id: articles,
            isEnabled,
            isApproved,
        };

        if (orderProp && orderDir) {
            query["order[" + orderProp + "]"] = orderDir;
        }

        if (pagination && page && perPage) {
            query["pagination"] = pagination;
            query["page"] = page;
            query["perPage"] = perPage;
        }

        if (isOnGlobe !== null) {
            query["isOnGlobe"] = isOnGlobe;
        }

        return this._makeRequest("/api/news_articles", { query });
    }

    newsArticlesRelated(id) {
        return this._makeRequest("/news-articles/get-related/" + id);
    }

    homeContents(isEnabled = true, isApproved = true) {
        return this._makeRequest("/api/home_contents");
    }

    menuLinks() {
        return this._makeRequest("/api/menu_links");
    }

    social() {
        return this._makeRequest("/api/social_media");
    }

    dataInstructions() {
        return this._makeRequest("/api/data_instructions", {
            query: { "order[id]": "desc" },
        });
    }

    advertBanners(isEnabled = true, area = "home") {
        return this._makeRequest("/api/home_banners", {
            query: { isEnabled, area },
        });
    }

    popUps(id, isEnabled = true) {
        return this._makeRequest("/api/pop_ups/" + id, {
            query: {
                isEnabled,
            },
        });
    }

    profileTypes(
        title = false,
        isResourceIndex = false,
        isDataIndex = false,
        isNetworkIndex = false,
        isSelectable = false
    ) {
        let query = { query: {} };

        if (title) {
            query = { query: { title } };
        }

        if (isResourceIndex) {
            query["query"]["isIndex"] = isResourceIndex;
        }

        if (isDataIndex) {
            query["query"]["isDataIndex"] = isDataIndex;
        }

        if (isNetworkIndex) {
            query["query"]["isNetworkIndex"] = isNetworkIndex;
        }

        if (isSelectable) {
            query["query"]["isSelectable"] = isSelectable;
        }

        query["query"]["order[title]"] = "asc";

        return this._makeRequest("/api/profile_types", query);
    }

    profiles(
        profile,
        isEnabled = true,
        isApproved = true,
        profileType = false
    ) {
        return this._makeRequest("/api/profiles", {
            query: {
                ...profile,
                profileType,
                isEnabled,
                isApproved,
            },
        });
    }

    getDataSubmitProfiles() {
        return this._makeRequest("/get-data-submit-profiles");
    }

    popularSearches(searchType) {
        return this._makeRequest("/get-popular-searches", {
            query: { search_type: searchType },
        });
    }

    currentUser() {
        if (!this.getToken()) {
            return Promise.resolve(false);
        }

        return this._makeRequest("/get-current-user");
    }

    currentUserRelationships() {
        if (!this.getToken()) {
            return Promise.resolve(false);
        }

        return this._makeRequest("/get-current-user-relationships");
    }

    setCurrentLocation() {
        return this._makeRequest("/network/set-current-location");
    }

    setLang(lang) {
        return this._makeRequest("/network/set-user-language/" + lang);
    }

    login(username, password) {
        let options = {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: { "Content-Type": "application/json" },
        };

        return fetch(this.apiUrl + "/api/login_check", options)
            .then((response) => {
                if (response.status === 401) {
                    throw new Error("Incorrect username or password");
                } else if (response.status < 200 || response.status >= 300) {
                    throw new Error("There was an error with your request");
                }

                return response.json();
            })
            .then(({ token }) => {
                if (!token) {
                    throw new Error("There was an error with your request");
                }

                this.setToken(token);
            });
    }

    sendPasswordResetEmail(email) {
        let options = {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: { "Content-Type": "application/json" },
        };

        return this._makeRequest("/network/send-password-reset-email", options);
    }

    settings() {
        return this._makeRequest("/api/website_settings.json");
    }

    userFavourites(id) {
        return this._makeRequest("/resource/user-favourite/" + id);
    }

    savedSearches(query) {
        return this._makeRequest("/api/searches", { query });
    }

    saveSearch(ids, type) {
        let options = {
            method: "POST",
            body: JSON.stringify({ ids, type }),
            headers: { "Content-Type": "application/json" },
        };

        return this._makeRequest("/save-search", options);
    }

    saveSearchUrl(ids, url) {
        let options = {
            method: "POST",
            body: JSON.stringify({ ids, url }),
            headers: { "Content-Type": "application/json" },
        };

        return this._makeRequest("/save-search-url", options);
    }

    removeSaveSearch(id) {
        return this._makeRequest("/remove-save-search/" + id);
    }

    updateRelationships(id, relationshipIds) {
        let options = {
            method: "POST",
            body: JSON.stringify({ id, relationshipIds }),
            headers: { "Content-Type": "application/json" },
        };

        return this._makeRequest("/profiles/update-relationships", options);
    }

    deleteRelationships(id, relationshipIds) {
        let ids = relationshipIds.join(",");

        let options = {
            method: "POST",
            body: JSON.stringify({ id, relationshipIds: ids }),
            headers: { "Content-Type": "application/json" },
        };

        return this._makeRequest("/profiles/delete-relationships", options);
    }

    userNotifications() {
        return this._makeRequest("/get-user-notifications");
    }

    markNotificationAsRead(id) {
        return this._makeRequest("/mark-as-read/" + id);
    }

    deleteNotification(id) {
        return this._makeRequest("/delete-notification/" + id);
    }

    dataFields() {
        return this._makeRequest("/api/export/data-fields");
    }

    _makeRequest(url, options = {}) {
        if (this.getToken()) {
            options.headers = options.headers || {};
            options.headers.authorization = "Bearer " + this.getToken();
        }

        if (options.query) {
            let query = this._encodeUri(options.query);
            url += "?" + query;

            delete options["query"];
        }

        return fetch(this.apiUrl + url, options)
            .then((res) => {
                if (res.status === 401) {
                    this.invalidateToken();
                    throw new AuthenticationError(res.statusText);
                } else if (res.status > 399) {
                    throw new Error(res.statusText);
                }

                return res.json();
            })
            .then((json) => {
                return json["hydra:member"] || json;
            });
    }

    _encodeUri(s) {
        let res = [];

        for (let key in s) {
            let kEnc = encodeURIComponent(key);
            if (s[key] instanceof Array) {
                for (let value of s[key]) {
                    res.push(kEnc + "[]=" + encodeURIComponent(value));
                }
            } else {
                res.push(kEnc + "=" + encodeURIComponent(s[key]));
            }
        }

        return res.join("&");
    }
}

export default new Api(
    process.env.REACT_APP_API_ENTRYPOINT,
    () => window.localStorage.getItem("token"),
    (token) => window.localStorage.setItem("token", token),
    () => window.localStorage.removeItem("token")
);

