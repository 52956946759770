import React from 'react';
import Api from "../../Api";
import SelectSearch from 'react-select-search';
import Loading from '../Loading';
import { Button } from "react-bootstrap";

class CollaboratorAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            selected: ''
        }
    }

    handleOnChange = (id) => {
        this.setState({ selected: id });
    }

    AddRelationship = () => {
        this.setState({ loading: true });

        Api.updateRelationships(this.props.user.id, this.state.selected)
            .then(response => {
                this.props.reload();
                this.setState({ loading: false, selected: '' })
            })
        ;
    }

    render() {
        return (
            <div className="collaborators__add">
                <h3>Assign Collaborator</h3>
                <p>If you would like to assign a new collaborator then use the form below. If a collaborator cannot be found then you can create a new one.</p>

                {this.props.options.length > 0 && 
                    <>
                        <SelectSearch 
                            search 
                            options={this.props.options} 
                            placeholder=" "  
                            printOptions="on-focus"
                            onChange={this.handleOnChange}
                            value={this.state.selected}
                        />
                        
                        {this.state.selected && 
                            <Button className="collaborators__add-btn" onClick={this.AddRelationship}>
                                {this.state.loading === true && 
                                    <Loading size="1x" />
                                }

                                {this.state.loading === false && 
                                    <>Add Collaborator</>
                                }
                            </Button>   
                        }
                    </>     
                }                
            </div>
        );
    }
}

export default CollaboratorAdd;