import React from 'react';
import RelationshipPopUp from './RelationshipPopUp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faTimes } from "@fortawesome/pro-solid-svg-icons";

class RelationshipInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentProfiles: {},
            loading: false,
            profilesList: [],
        };
    }

    setProfilesList = (profiles) => {
        this.setState({ profilesList: profiles })
    }

    setSelectedProfiles = (profiles) => {
        let combinedProfiles = this.props.selectedProfiles.concat(profiles);
        let uniqueProfiles = [...new Set(combinedProfiles)];

        this.props.setToAdd(uniqueProfiles);
        this.props.setSelectedProfiles(uniqueProfiles);
    }

    render() {
        let relationshipTypes = false;

        if (this.props.userRelationshipsByType) {
            relationshipTypes = Object.keys(this.props.userRelationshipsByType);
        }

        return(
            <div className="relationships">
                <div className="relationships__header">
                    <h3>
                        Related key search terms
                    </h3>
                    <RelationshipPopUp 
                        setRelationships={this.props.setRelationships} 
                        setProfilesList={this.setProfilesList} 
                        setSelectedProfiles={this.setSelectedProfiles}
                        selectedProfiles={this.props.selectedProfiles}
                        ignoreTypes={this.props.ignoreTypes}
                        user={this.props.user}
                        relationships={this.props.userRelationships}
                    />
                </div>

                <div className="relationships__current-profiles-wrap">
                    {this.props.selectedProfiles && this.props.selectedProfiles.length > 0 && 
                        <>
                            <p>The following key search terms will be <strong>assigned</strong>:</p>
                            <div className="relationships__current-profiles">
                                {this.props.selectedProfiles.map((item, index) => {
                                    return (
                                        <div className="relationships__profile" key={index}>
                                            {this.state.profilesList[parseInt(item)]}
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }

                    {this.props.displayAssignedProfiles === true && this.props.userRelationshipsByType &&
                        <>
                            <p>You are already assigned to the following key search terms:</p>

                            {relationshipTypes.map((type, index) => {
                                // don't display institutions
                                if (type === 'Institutions') {
                                    return null;
                                }

                                return (
                                    <div key={index} className="relationships__type">
                                        <div className="relationships__type-header">{type}</div>
                                        <div className="d-flex flex-wrap">
                                            {this.props.userRelationshipsByType[type].map((item, index) => (
                                                <div 
                                                    className={"relationships__profile" + (this.props.toDelete.includes(item.id) ? " to-delete" : "" )} 
                                                    key={index} 
                                                    onClick={() => this.props.toggleDelete(item.id)}
                                                >
                                                    {item.title}
                                                    <div className="relationshios__profile-delete">
                                                        {this.props.toDelete.includes(item.id) && 
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        }

                                                        {!this.props.toDelete.includes(item.id) && 
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default RelationshipInput;