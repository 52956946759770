import React from 'react';
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export const RegistrationComplete = () => {
    return (
        <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
            <Alert.Heading>Registration Complete!</Alert.Heading>
            <p>Thank you for confirming your email. You can now <Link to="/log-in">log in</Link>.</p>
        </Alert>
    );
}