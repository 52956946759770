import React, { useState, useEffect } from 'react';

const ToolTip = props => {
    let [pos, setPos] = useState([0, 0]);
    let tooltip = React.createRef();

    useEffect(() => {
        const setMousePosFromEvent = event => {
            if (tooltip.current) {
                setPos([
                    event.clientX - tooltip.current.clientWidth / 2,
                    event.clientY - 90,
                ]);
            }
        };

        window.addEventListener("mousemove", setMousePosFromEvent);

        return () => {
            window.removeEventListener("mousemove", setMousePosFromEvent);
        };
    });

    return <div
        className="globe-tooltip p-4"
        style={{top: pos[1] + "px", left: pos[0] + "px", display: pos[0] && pos[1] ? "block" : "none"}}
        ref={tooltip}
    >
        {props.country.properties.continent}
        <div className="triangle" />
    </div>;
};

export default ToolTip;
