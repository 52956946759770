import React from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios";
import Api from '../../Api';

const FILE_SIZE_LIMIT = 10000000; //10MB

class DataCsvForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectType: false,
            data_csv: '',
            consent: false,
            loading: false,
            success: null,
            errorMessage: '',
            dataInstructions: '',
            dataInstructionsFile: false,
            projectTypes: false,
            // totalRows: '',
            // percentageComplete: 0,
            fileError: false,
        }

        this.uploadFile = this.uploadFile.bind(this);
        // this.interval = false;
    }

    componentDidMount() {
        Promise.all([
            Api.dataInstructions(),
            Api.getDataSubmitProfiles()
        ]).then(responses => {
            const dataInstruction = responses[0][0];
            const projectTypes = responses[1];
            let otherIndex = false;

            projectTypes.forEach((profile, index) => {
                if (profile.title == 'Other') {
                    otherIndex = index;
                }
            })

            if (otherIndex) {
                projectTypes.push(projectTypes.splice(otherIndex, 1)[0]);
            }

            this.setState({
                projectTypes,
                dataInstructions: dataInstruction.instructions,
                dataInstructionsFile: dataInstruction.file.filePath
            })
        });
    }

    // componentWillUnmount() {
    //     this.cancelInterval();
    // }

    handleInputChange = (event) => {
        const target = event.target;

        let fieldValue;

        if (target.type === 'select-multiple') {
            let value = Array.from(event.target.selectedOptions, option => option.value);
            fieldValue = value.join(', ');
        } else if (target.type === 'checkbox') {
            fieldValue = target.checked;
        } else {
            fieldValue = target.value;
        }

        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    uploadFile(event) {
        let file = event.target.files[0];
        
        if (file) {
            this.setState({ data_csv: file });
        }
    }

    handleSubmit = (event) => {
        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        if (thisForm.checkValidity() !== false && this.state.data_csv) {
            if (this.state.data_csv.size > FILE_SIZE_LIMIT) {
                this.setState({ fileError: true });
            } else {
                this.setState({ fileError: false });
                const reader = new FileReader()

                // reader.onload = event => {
                //     var contents = event.target.result;
                //     contents = contents.split("\n");

                //     var totalRows = contents.length - 3;

                //     this.setState({ totalRows });
                // }

                reader.readAsText(this.state.data_csv);

                const FormData = require('form-data');
                const form = new FormData();

                form.append('project_type', this.state.projectType);
                form.append('consent', this.state.consent);
                form.append('data_csv', this.state.data_csv);

                let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

                this.setState({ loading: true });

                axios.post(entryPoint + '/data-import/import-csv', form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'authorization': 'Bearer ' + window.localStorage.getItem('token')
                    }
                })
                .then(response => {
                    if (response.data.errors && response.data.errors.length > 0) {
                        let errorMessage = '';
                        response.data.errors.forEach((item, index) => {
                            errorMessage += item + "<br />";
                        })

                        this.setState({
                            errorMessage: errorMessage,
                            success: false,
                            loading: false,
                        })
                    } else {
                        this.setState({
                            projectType: false,
                            data_csv: '',
                            consent: false,
                            loading: false,
                            success: true,
                            errorMessage: '',
                        });
                        
                        this.props.loginRefresh();
                    }
                })
                .catch(error => {
                    console.log(error);
                });

                // let that = this;

                // this.interval = setInterval(function() {
                //     axios.get(entryPoint + '/data-import/count', {
                //         headers: {
                //             'Content-Type': 'multipart/form-data',
                //             'authorization': 'Bearer ' + window.localStorage.getItem('token')
                //         }
                //     })
                //     .then(response => {
                //         let percentageComplete = Math.round(100 - ((response.data.count / that.state.totalRows) * 100));
                //         that.setState({ percentageComplete });

                //         if (response.data.count === 0) {
                //             that.cancelInterval();
                //         }
                //     });
                // }, 5000);
            }
        }
    }

    // cancelInterval = () => {
    //     clearTimeout(this.interval);
    // }

    render() {
        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

        return (
            <div className="data-upload-form">
                {(this.state.dataInstructions || this.state.dataInstructionsFile) && 
                    <div className="data-upload-form__instructions">
                        <div dangerouslySetInnerHTML={{ __html: this.state.dataInstructions }}></div>
                        {this.state.dataInstructionsFile && 
                            <p>
                                <a 
                                    className="filter-page-header-btn" 
                                    href={entryPoint + '/media/' + this.state.dataInstructionsFile} 
                                    target="_blank"
                                >
                                    <span><FontAwesomeIcon icon={faCloudDownloadAlt} /></span>
                                    Download the data template here
                                </a>
                            </p>
                        }
                    </div>
                }

                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Thank you!</Alert.Heading>
                        {<p>
                            Thank you for your data submission. This has been added to our data import queue. You 
                            will be notified when all of your data has been uploaded.
                        </p>}
                    </Alert>
                }

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>There was an error uploading your data!</Alert.Heading>
                        <div className="data-upload-form__errors">
                            <div dangerouslySetInnerHTML={{__html: this.state.errorMessage}}></div>
                        </div>
                    </Alert>
                }

                {this.state.success !== true && 
                    <Form noValidate className="network-create-account-form data-upload-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" encType="multipart/form-data">
                    
                        <Form.Group controlId="data-description">
                            <Form.Label>Select your Project Type</Form.Label>
                            {this.state.projectTypes && 
                                <Form.Control as="select" size="md"  name="projectType" onChange={this.handleInputChange} required multiple>
                                    {this.state.projectTypes.map((item, index) => (
                                        <option key={index} value={item.id}>{item.title}</option>
                                    ))}
                                </Form.Control>
                            }
                        </Form.Group>

                        {this.state.fileError === true &&
                            <Alert className="mb-4" variant="danger" onClose={() => (this.setState({fileError: false}))}>
                                <Alert.Heading>Error Uploading File</Alert.Heading>
                                <p>Please ensure it is less than 10MB.</p>
                            </Alert>
                        }

                        <Form.Group controlId="data-file">
                            <Form.Label>Upload your CSV File (Max. 10MB)</Form.Label>
                            <Form.Control name="data_csv" type="file" required accept=".csv" onChange={this.uploadFile} />
                        </Form.Group>

                        <div className="custom-control custom-checkbox">
                            <input name="consent" type="checkbox" className="custom-control-input" id="data-upload-terms-checkbox" onChange={this.handleInputChange} required feedback="You must agree before submitting" />
                            <label className="custom-control-label" htmlFor="data-upload-terms-checkbox">
                                By submitting my data I consent to the terms and conditions of data collection as stated in the <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>

                        <Button className="mx-0 mt-5 mb-4 font-smooth text-white filter-page-header-btn"
                            variant="ternary"
                            type="submit"
                        >
                                {this.state.loading === true && 
                                    <>
                                        <span><FontAwesomeIcon icon={faCloudUploadAlt} /></span>
                                        Uploading
                                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faCircleNotch} size="2x" color="#FF7442" spin />
                                    </>
                                    
                                }
                                {this.state.loading === false && 
                                    <>
                                        <span><FontAwesomeIcon icon={faCloudUploadAlt} /></span>
                                        Submit Data CSV
                                    </>
                                }
                        </Button>
                    </Form>
                }
            </div>
        );
    }
}

export default DataCsvForm;