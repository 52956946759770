import React from 'react';
import Api from "../../Api";
import Loading from "../Loading";
import ResourceTreeNode from "./ResourceTreeNode";

class ResourceTree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            children: [],
        }
    }

    componentDidMount() {
        Api.resourceTrees({ isEnabled: true, 'exists[parent]': false })
            .then(children => {
                this.setState({ children, loading: false });
            })
        ;
    }

    render() {    
        return(
            <>
                <h3>Resource Index</h3>
                <p>
                    Below is an index of the resource section to assit you in locating the resources on the system. 
                    Selecting a heading will display any sub sections, as well as running any searches based on that title.
                </p>

                {this.state.loading &&
                    <Loading size="2x" />
                }

                {this.state.children.map((item, index) => {
                   return <ResourceTreeNode key={index} item={item} level={1} onClick={this.props.onClick}/>
                })}
            </>
        );
    }
}

export default ResourceTree;