import React from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import { UserContext } from "../../UserContext";
import BackToAccount from './BackToAccount';
import Api from '../../Api';

class UpdateAccount extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            gotUser: true,
            loading: false,
            success: null,
            title: '',
            first_name: '',
            surname: '',
            email_address: '',
            errorMessage: 'The form could not be submitted. Please check and try again.',
            organisation: '',
            affiliation: '',
            organisationType: 'University/Higher Education',
            postCode: '',
            city: '',
            country: '',
            institutionInformation: '',
            unitCapabilities: '',
            fieldResearchUnits: '',
            fieldResearchUnitsAdditional: '',
            labCapabilities: '',
            funder: '',
            expertise: '',
            typeOfProfile: 'Policy maker',
            otherProfiles: '',
            website: '',
            researchgate: '',
            twitter: '',
            linkedin: '',
            orcid: '',
            aboutMe: '',
            profilePicture: '',
            geoLocation: 'show',
            currentLocation: '',
            countries: [],
        }

        this.scrollRef = React.createRef();
    }

    scrollTo() {
        this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    componentDidMount() {
        this.setState({
            ...this.context,
            email_address: this.context.email,
        });

        const promises = [
            Api.profiles({ 'order[title]': 'asc', 'page': 1, 'perPage': 1000, 'noRelationships': 1 }, true, true, 40),
            Api.profiles({ title: this.context.country, 'noRelationships': 1 }, true, true)
        ];

        Promise.all(promises)
            .then((responses) => {
                const country = (responses[1].length ? responses[1][0].id : "");
                this.setState({ countries: responses[0], country });
            })
        ;
    }

    componentDidUpdate() {
        if (!(this.state.title || this.state.first_name || this.state.surname || this.state.email_address)) {
            this.setState({
                ...this.context,
                email_address: this.context.email,
            });
        }
    }

    handleSubmit = (event) => {
        this.setState({ loading: true });

        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        if (thisForm.checkValidity() !== false) {
            const FormData = require('form-data');
            const form = new FormData();
            
            form.append('title', this.state.title);
            form.append('first_name', this.state.first_name);
            form.append('surname', this.state.surname);
            form.append('email_address', this.state.email_address);
            form.append('organisation', this.state.organisation);
            form.append('affiliation', this.state.affiliation);
            form.append('organisationType', this.state.organisationType);
            form.append('postCode', this.state.postCode);
            form.append('city', this.state.city);
            form.append('country', this.state.country);
            form.append('institutionInformation', this.state.institutionInformation);
            form.append('unitCapabilities', this.state.unitCapabilities);
            form.append('fieldResearchUnits', this.state.fieldResearchUnits);
            form.append('fieldResearchUnitsAdditional', this.state.fieldResearchUnitsAdditional);
            form.append('labCapabilities', this.state.labCapabilities);
            form.append('funder', this.state.funder);
            form.append('expertise', this.state.expertise);
            form.append('vectorsPathogenAccess', this.state.vectorsPathogenAccess);
            form.append('typeOfProfile', this.state.typeOfProfile);
            form.append('otherProfiles', this.state.otherProfiles);
            form.append('website', this.state.website);
            form.append('researchgate', this.state.researchgate);
            form.append('twitter', this.state.twitter);
            form.append('linkedin', this.state.linkedin);
            form.append('orcid', this.state.orcid);
            form.append('aboutMe', this.state.aboutMe);
            form.append('geoLocation', this.state.geoLocation);
            form.append('currentLocation', this.state.currentLocation);
            form.append('profilePicture', this.state.profilePicture);

            let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

            axios.post(entryPoint + '/network/update-profile', form, {
                headers: {
                    authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            })
                .then(response => {
                    if (response.data.success && response.data.emailValidation.success) {
                        this.setState({ success: true });

                        if (response.data.jwt) {
                            window.localStorage.setItem('token', response.data.jwt);
                        }
                    }

                    if (response.data.success === false && response.data.emailValidation.success === false) {
                        this.setState({ success: false, errorMessage: response.data.emailValidation.message });
                    }

                    this.setState({ loading: false });
                    this.props.loginRefresh();
                    this.scrollTo();

                    thisForm.className = "network-update-profile-form my-auto";
                })
                .catch(error => {
                    console.error(error);
                })
            ;
            
        } else {
            this.setState({ loading: false, success: false });
            this.scrollTo();
        }
    }

    uploadFile = (event) => {
        let file = event.target.files[0];
        
        if (file) {
            if (file.size < 1048576) { // no larger than 1mb
                this.setState({ profilePicture: file });
                event.target.setCustomValidity('');
            } else {
                event.target.setCustomValidity('File too large');
            }
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    render() {
        return (
            <div ref={this.scrollRef}>
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>Your account has been updated.</p>
                    </Alert>
                }

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{this.state.errorMessage}</p>
                    </Alert>
                }

                <Form noValidate className="network-update-profile-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" id="update-details-form" encType="multipart/form-data">
                    <Form.Group controlId="update-profile-title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control name="title" size="md" type="text" value={this.state.title} onChange={this.handleInputChange} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid title.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="update-profile-first-name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control name="first_name" size="md" type="text" value={this.state.first_name} onChange={this.handleInputChange} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid first name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="update-profile-surname">
                        <Form.Label>Surname</Form.Label>
                        <Form.Control name="surname" size="md" type="text" value={this.state.surname} onChange={this.handleInputChange} required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid surname.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="update-profile-email-address">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control name="email_address" size="md" type="text" value={this.state.email_address} onChange={this.handleInputChange} required />
                    </Form.Group>

                    <Form.Group controlId="update-profile-organisation">
                            <Form.Label>Organisation *</Form.Label>
                            <Form.Control name="organisation" size="md" type="text" value={this.state.organisation} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid organisation.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-affiliation">
                            <Form.Label>Affiliation *</Form.Label>
                            <Form.Control name="affiliation" size="md" type="text" value={this.state.affiliation} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid affiliation.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-organisation-type">
                            <Form.Label>Organisation Type *</Form.Label>
                            <Form.Control name="organisationType" size="md" as="select" value={this.state.organisationType} onChange={this.handleInputChange} required>
                                <option>University/Higher Education</option>
                                <option>Research Institute</option>
                                <option>Public Health Agency</option>
                                <option>Private Company</option>
                                <option>Professional Network</option>
                                <option>Funding Agency</option>
                                <option>News Outlet/Journal/Publisher</option>
                                <option>Other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid organisation Type.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-postcode">
                            <Form.Label>Post Code</Form.Label>
                            <Form.Control name="postCode" size="md" type="text" value={this.state.postCode} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid post code.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-city">
                            <Form.Label>City</Form.Label>
                            <Form.Control name="city" size="md" type="text" value={this.state.city} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid city.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-country">
                            <Form.Label>Country *</Form.Label>
                            <Form.Control name="country" size="md" as="select" value={this.state.country} onChange={this.handleInputChange} required>
                                <option value=""></option>
                                {this.state.countries.map(country => (
                                    <option value={country.id}>{country.title}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid country.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-institution-information">
                            <Form.Label>Institution Information</Form.Label>
                            <Form.Control name="institutionInformation" size="md" rows="3" as="textarea" value={this.state.institutionInformation} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid institution information.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-unit-capabilities">
                            <Form.Label>Unit Capabilities</Form.Label>
                            <Form.Control name="unitCapabilities" size="md" rows="3" as="textarea" value={this.state.unitCapabilities} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Unit capabilities.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-field-research-units" id="update-profile-field-research-units">
                            <Form.Label>Field Research Units</Form.Label>
                            <Form.Check inline name="fieldResearchUnits" type="radio" label="Yes" value="1" onChange={this.handleInputChange} checked={(this.state.fieldResearchUnits == 1 ? 'checked' : '' )} />
                            <Form.Check inline name="fieldResearchUnits" type="radio" label="No" value="0" onChange={this.handleInputChange} checked={(this.state.fieldResearchUnits == 0 ? 'checked' : '' )} />
                        </Form.Group>

                        <Form.Group controlId="update-profile-field-research-units-additional">
                            <Form.Label>Field Research Units Additional Information</Form.Label>
                            <Form.Control name="fieldResearchUnitsAdditional" size="md" rows="3" as="textarea" value={this.state.fieldResearchUnitsAdditional} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid field research units additional information.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-lab-capabilities">
                            <Form.Label>Lab Capabilities for Diagnosis for Vector-borne Diseases</Form.Label>
                            <Form.Control name="labCapabilities" size="md" rows="3" as="textarea" value={this.state.labCapabilities} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid lab capabilities.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-funder">
                            <Form.Label>Funder</Form.Label>
                            <Form.Control name="funder" size="md" type="text" value={this.state.funder} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid funder.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-expertise">
                            <Form.Label>Expertise *</Form.Label>
                            <Form.Control name="expertise" size="md" type="text" value={this.state.expertise} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid expertise.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-field-vectors-pathogen-access">
                            <Form.Label>Which vectors and pathogens do you have access to?</Form.Label>
                            <Form.Control name="vectorsPathogenAccess" size="md" rows="3" as="textarea" value={this.state.vectorsPathogenAccess} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid vectors and pathogens access information.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-type-of-profile">
                            <Form.Label>Type of Profile *</Form.Label>
                            <Form.Control name="typeOfProfile" size="md" as="select" value={this.state.typeOfProfile} onChange={this.handleInputChange} required>
                                <option>Policy maker</option>
                                <option>Student</option>
                                <option>Funder</option>
                                <option>Researcher</option>
                                <option>General</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid profile type.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-other-profiles">
                            <Form.Label>Other Profiles</Form.Label>
                            <Form.Control name="otherProfiles" size="md" rows="3" as="textarea" value={this.state.otherProfiles} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide valid other profiles.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-website">
                            <Form.Label>Website/URL</Form.Label>
                            <Form.Control name="website" size="md" type="text" value={this.state.website} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid website.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-researchgate">
                            <Form.Label>ResearchGate Profile URL</Form.Label>
                            <Form.Control name="researchgate" size="md" type="text" value={this.state.researchgate} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid ResearchGate URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-twitter">
                            <Form.Label>Twitter Profile URL</Form.Label>
                            <Form.Control name="twitter" size="md" type="text" value={this.state.twitter} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Twitter URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-linkedin">
                            <Form.Label>LinkedIn Profile URL</Form.Label>
                            <Form.Control name="linkedin" size="md" type="text" value={this.state.linkedin} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid LinkedIn URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-orcid">
                            <Form.Label>ORCID Profile URL</Form.Label>
                            <Form.Control name="orcid" size="md" type="text" value={this.state.orcid} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid ORCID URL.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="update-profile-about-me">
                            <Form.Label>About me</Form.Label>
                            <Form.Control name="aboutMe" size="md" rows="3" as="textarea" value={this.state.aboutMe} onChange={this.handleInputChange} />
                            <Form.Control.Feedback type="invalid">
                                Please provide vaild information about you.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="create-account-profile-picture">
                            <Form.Label>Change Profile Picture</Form.Label>
                            {this.state.profilePicture && this.state.profilePicture.filePath &&
                                <div>
                                    <img 
                                        src={process.env.REACT_APP_API_ENTRYPOINT + '/media/' + this.state.profilePicture.filePath} 
                                        style={{ maxWidth: '200px', paddingBottom: "5px" }} 
                                    />
                                </div>
                            }
                            <Form.Control name="profilePicture" type="file" accept=".jpg,.jpeg,gif,.png" onChange={this.uploadFile} />
                            <Form.Control.Feedback type="invalid">
                                Please upload a valid .jpg, .jpeg, gif or .png file that is no larger than 1mb.
                            </Form.Control.Feedback>
                            <Form.Text id="profile-picture-help">
                                Please upload a valid .jpg, .jpeg, gif or .png file that is no larger than 1mb.
                            </Form.Text>
                        </Form.Group>

                        <h3>Your Location</h3>
                        <p>Show a set location, a dynamic location or hide your location from other users on your profile.</p>
                        
                        <ul>
                            <li><strong>Show Set Location</strong> - Manually enter your location.</li>
                            <li><strong>Hide Location</strong> - Don't show any location on your profile.</li>
                            <li><strong>Show Dynamic Location</strong> - Every time you login, get your location using your IP address (not 100% accurate).</li>
                        </ul>
                        
                        <Form.Group controlId="update-profile-geo-location">
                            <Form.Label>Geo Location *</Form.Label>
                            <Form.Control name="geoLocation" size="md" as="select" value={this.state.geoLocation} onChange={this.handleInputChange} required>
                                <option value="show">Show Set Location</option>
                                <option value="hide">Hide Location</option>
                                <option value="dynamic">Dynamic Location</option>

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid selection.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {this.state.geoLocation === 'show' &&
                            <Form.Group controlId="update-profile-current-location">
                                <Form.Label>Current Location</Form.Label>
                                <Form.Control name="currentLocation" size="md" type="text" value={this.state.currentLocation} onChange={this.handleInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid location.
                                </Form.Control.Feedback>
                            </Form.Group>
                        }

                    <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                        variant="ternary"
                        size="md"
                        type="submit"
                        block>
                            {this.state.loading === true && 
                                <FontAwesomeIcon icon={faCircleNotch} size="2x" color="#FF7442" spin />
                            }
                            {this.state.loading === false && 
                                <>Update Profile</>
                            }
                    </Button>

                    <BackToAccount />
                </Form>
            </div>
        )
    }
}

export default UpdateAccount;
