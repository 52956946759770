import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField,
} from "@material-ui/core";
import axios from "axios";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const GroupTable = ({ headers, data, fetchGroups, groupId }) => {
    const [state, setState] = useState({
        data: data,
        page: 0,
        rowsPerPage: 5,
        groupId: groupId.split("/").pop(),
        loading: false,
    });
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setError(false); // Reset error when user starts typing
    };

    const handleSubmit = async () => {
        setState((prev) => ({ ...prev, loading: true }));

        const entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        const url = `${entryPoint}/api/user_groups/${state.groupId}/add_user_by_email`;

        try {
            await axios.post(
                url,
                { user_email: email }, // Correct payload
                {
                    headers: {
                        authorization: `Bearer ${window.localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            // alert("User added to the group successfully!");
            handleClose(); // Close dialog
            await fetchGroups();
        } catch (error) {
            console.error("Error adding user to the group:", error);
            setError("Error adding user to the group:");
            // alert("Failed to add user to the group.");
        } finally {
            setState((prev) => ({ ...prev, loading: false }));
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        await setEmail(""); // Clear input field
        setOpen(false);
    };

    const deleteUser = async (userId) => {
        setState((prev) => ({ ...prev, loading: true }));

        const entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        const url = `${entryPoint}/api/user_groups/${state.groupId}/remove_users`;

        try {
            await axios.delete(url, {
                data: { selected_ids: [userId] },
                headers: {
                    authorization: `Bearer ${window.localStorage.getItem(
                        "token"
                    )}`,
                },
            });
            await fetchGroups();
            // alert("User deleted successfully!");
        } catch (error) {
            console.error("Error deleting relationships:", error);
            // alert("Failed to update relationships.");
        } finally {
            setState((prev) => ({ ...prev, loading: false }));
        }
    };

    const handleChangePage = (event, newPage) => {
        setState({
            ...state,
            page: newPage,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setState({
            ...state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const { page, rowsPerPage, loading } = state;

    return (
        <div className="border rounded-lg shadow p-1">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={`header-${index}`}>
                                    {header}
                                </TableCell>
                            ))}
                            <TableCell>
                                <Button
                                    onClick={() => handleClickOpen()}
                                    size="small"
                                    variant="text"
                                    style={{ color: "#ff7442" }}
                                >
                                    Add user
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((user, index) => (
                                <TableRow key={`user-row-${index}`}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => deleteUser(user.id)}
                                            size="small"
                                            variant="text"
                                            style={{ color: "#ff7442" }}
                                            disabled={loading}
                                        >
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {data.length === 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={headers.length + 1}
                                    style={{ textAlign: "center" }}
                                >
                                    No data available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length > 0 && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="add-user-dialog-title"
                aria-describedby="add-user-dialog-description"
                style={{ zIndex: 9999 }}
            >
                <DialogTitle id="add-user-dialog-title">Add User</DialogTitle>
                <DialogContent>
                    <DialogContentText id="add-user-dialog-description">
                        Please provide an email that belongs to a user you wish
                        to add to this user group.
                    </DialogContentText>
                    <form noValidate autoComplete="off">
                        <TextField
                            id="user-email"
                            label="User Email"
                            placeholder="Enter user email"
                            value={email}
                            onChange={handleEmailChange}
                            error={error}
                            helperText={
                                error
                                    ? "Error adding user to the group invalid email address or the user does not exist."
                                    : ""
                            }
                            fullWidth
                            variant="filled"
                            margin="normal"
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        aria-label="add-user-button"
                    >
                        Add
                    </Button>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        aria-label="cancel-add-user-button"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

