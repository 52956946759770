import React from 'react';
import { Link} from "react-router-dom";
import { Button } from "react-bootstrap";
import Api from "../../Api";
import CollaboratorList from './CollaboratorList';
import CollaboratorAdd from './CollaboratorAdd';
import BackToAccount from './BackToAccount';
import Loading from '../Loading';

class Collaborators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            assignedCollaborators: false,
            addableCollaborators: [],
            loading: true,
            toDelete: [],
        }
    }

    componentDidMount() {
        this.loadCollaborators();
    }

    manageDelete = (id) => {
        let toDelete = this.state.toDelete.slice(0);
        let index = toDelete.indexOf(id);

        if (index === -1) {
            toDelete.push(id);
            this.setState({ toDelete: toDelete })
        } else {
            toDelete.splice(index, 1);
            this.setState({ toDelete: toDelete })
        }
    }

    handleDelete = () => {
        Api.deleteRelationships(this.props.user.id, this.state.toDelete)
            .then(response => {
                this.loadCollaborators();
                this.setState({ toDelete: [] })
            })
        ;
    }

    loadCollaborators = () => {
        let collaboratorId = process.env.REACT_APP_COLLABORATOR_PROFILE_TYPE_ID;

        Api.search({ resource_type: "profile", profile_type: collaboratorId, relationship_ids: this.props.user.id }, true, true)
            .then(response => {
                let collaborators = response.data;

                this.setState({ assignedCollaborators: collaborators, loading: false });

                let collaboratorIds = [];
                
                collaborators.forEach((collaborator, index) => {
                    collaboratorIds.push(collaborator.id);
                })

                Api.profiles({ 'page': 1, 'perPage': 1000, 'noRelationships': 1 }, true, true, collaboratorId)
                    .then((response) => {
                        let selectOptions = [];

                        response.forEach((collaborator, index) => {
                            if (!collaboratorIds.includes(collaborator.id)) {
                                selectOptions.push({ value: collaborator.id.toString(), name: collaborator.title });
                            }
                        })

                        this.setState({ addableCollaborators: selectOptions });
                    })
                ;
            })
        ;
    }

    render() {
        return (
            <div className="collaborators">
                {this.state.loading && <Loading size="2x" />}

                {this.state.loading === false && 
                    <>
                        <CollaboratorList 
                            user={this.props.user} 
                            collaborators={this.state.assignedCollaborators} 
                            toDelete={this.state.toDelete} 
                            manageDelete={this.manageDelete} 
                            handleDelete={this.handleDelete}
                        />

                        <CollaboratorAdd 
                            user={this.props.user} 
                            options={this.state.addableCollaborators}
                            reload={this.loadCollaborators} 
                        />

                        <div className="collaborators__create">
                            <Button as={Link} to={'/account/create-collaborator'}>Create New Collaborator</Button>
                        </div>

                        <BackToAccount />
                    </>
                }
            </div>
        );
    }
}

export default Collaborators;