import React from 'react';
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import PopUp from './PopUp';
import { Alert, Button, Form } from 'react-bootstrap';

const SearchUrlForm = ({ onFormSubmit }) => {
    const [url, setUrl] = useState("");
    const [success, setSuccess] = useState(null);

    const onSubmit = () => {
        onFormSubmit(url)
            .then(response => {
                setSuccess(true);
            })
            .catch(err => {
                setSuccess(false);
            })
        ;
    }

    return <>
        {success === true &&
            <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                <Alert.Heading>Success!</Alert.Heading>
                <p>Search URL saved successfully.</p>
            </Alert>
        } 

        {success === false &&
            <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                <Alert.Heading>There was an error submitting the form</Alert.Heading>
            </Alert>
        } 

        {success !== true &&
            <Form.Group controlId="login-email">
                <Form.Label>URL</Form.Label>
                <Form.Control 
                    name="url" 
                    size="sm" 
                    type="text" 
                    value={url} 
                    onChange={(e) => setUrl(e.target.value)} 
                    required 
                />
                <Form.Text className="text-muted">
                    The url can only contain letters, number and hyphens (e.g my-saved-search). You also need to make sure it's unique.
                </Form.Text>
            </Form.Group>
        }

        <hr />

        {url !== '' && success !== true &&
            <Button className="font-smooth text-white"
                variant="ternary"
                size="sm"
                type="submit"
                block
                onClick={() => onSubmit()}
            >
                Submit
            </Button>
        }
    </>
}

const SearchUrlPopUp = ({ onSubmit }) => {
    const [show, setShow] = useState(false);

    const close = () => {
        setShow(false);
    }

    return <>
        <div className="search-input-save" onClick={() => setShow(true)}>
            Save As Url <FontAwesomeIcon className="save-icon" icon={faLink} />
        </div>

        <PopUp 
            size="md" 
            className="search-url-modal" 
            header={<h3>Save Search URL</h3>} 
            content={<SearchUrlForm onSubmit={onSubmit} onFormSubmit={onSubmit} />} 
            open={show} 
            close={close} 
        />
    </>
}

export default SearchUrlPopUp;