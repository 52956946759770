import React from 'react';
import { Link } from "react-router-dom";

const NewsArchiveListItem = (props) => {
    let date = new Date(props.article.publishDate);
    return (
        <div key={props.article.id} className="news-archive__item">
            <p className="date">{date.toDateString()}</p>
            <h3>{props.article.title}</h3>
            <p>{props.article.shortDescriptionSample}</p>
            <Link to={'/news/' + props.article.id}>Read More</Link>
        </div>
    );
}

export default NewsArchiveListItem;