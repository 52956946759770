import React from 'react';
import Api from '../Api';
import NewsArchiveListItem from './NewsArchiveListItem';
import Loading from './Loading';

class NewsArchive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            page: 1,
            loading: false,
            loadMore: true,
        }

        this.loadMore = () => {
            this.getArticles();
        }

        this.pageAmount = 6;
    }

    componentDidMount() {
        this.getArticles();
    }

    getArticles() {
        this.setState({ loading: true });

        Api.newsArticles([], true, true, 'publishDate', 'desc', true, this.state.page, this.pageAmount)
            .then((response) => {
                this.setState({ articles: this.state.articles.concat(response), page: this.state.page + 1, loading: false });
                
                if (response.length < this.pageAmount) {
                    this.setState({ loadMore: false })
                }
            })
            .catch(error => {
                console.error(error);
            })
        ;
    }

    render() {
        return (
            <>
                {this.state.articles.length && this.state.articles && 
                    <div className="news-archive">
                        {this.state.articles.map((article, index) =>
                            <NewsArchiveListItem key={index} article={article} />
                        )}
                    </div>
                }

                {this.state.loading === false && this.state.loadMore === true &&
                    <button className="btn btn-primary" onClick={this.loadMore}>Load More</button>
                }

                {this.state.loading === true &&
                    <div>
                        <Loading size="2x" />
                    </div>
                }
                
            </>
        );
    }
}

export default NewsArchive;