import React from 'react';
import FilterPage from './../FilterPage/FilterPage';
import NetworkLetterList from './NetworkLetterList';
import NetworkUserDetailList from './NetworkUserDetailList';
import NetworkUserItem from './NetworkUserItem';
import Api from '../../Api';
import { Row } from "react-bootstrap";

class NetworkFilterPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.criteria = props.profile ? [props.profile] : [];

        this.state = {
            loading: false,
            users: [],
            letter: false,
            searched: false,
            collaborators: [],
            institutions: [],
            newUsers: [],
            selectableIds: false,
        }

        this.searchInputRef = React.createRef();
    }

    componentDidMount() {
        this.props.setFilterPageClass();

        const collaboratorId = process.env.REACT_APP_COLLABORATOR_PROFILE_TYPE_ID;
        const institutionsId = process.env.REACT_APP_INSTITUTIONS_PROFILE_TYPE_ID;

        const promises = [
            Api.search({ resource_type: "profile", profile_type: collaboratorId, relationship_ids: this.props.user.id }, true, true),
            Api.search({ resource_type: "profile", profile_type: institutionsId, relationship_ids: this.props.user.id }, true, true),
            Api.search({ resource_type: 'user', order_by_property: 'createdAt', order_by_direction: 'desc', limit: 16 }, true, true)
        ];

        Promise.all(promises)
            .then(responses => {
                const collaborators = responses[0].data;
                const institutions = responses[1].data;
                const newUsers = responses[2].data;

                this.setState({ collaborators, institutions, newUsers });
            })
        ;

        this.loadSessionStorage();
    }
    
    loadSessionStorage() {
        const urlParams = new URLSearchParams(window.location.search);
        const paramIds = urlParams.get('ids');

        if (!paramIds) {
            const sessionCriteriaString = window.sessionStorage.getItem('networkCriteria');
            const sessionCriteria = JSON.parse(sessionCriteriaString);

            if (sessionCriteria) {
                this.onCriteriaChange(sessionCriteria);
            }

            const letter = window.sessionStorage.getItem('networkLetter');

            if (letter) {
                this.setState({ letter });
                this.search(letter);
            }
        }
    }

    componentWillUnmount() {
        this.props.setFilterPageClass(true);
    }

    addCriteria = criteria => {
        this.searchInputRef.current.addCriteria(criteria);
    }

    removeCriteria = criteria => {
        this.searchInputRef.current.removeCriteria(criteria);
    }

    clearCriteria = () => {
        this.searchInputRef.current.clearCriteria();
    }

    onProfileClick = (profile) => {
        let isCriteria = false;

        this.criteria.forEach((item, index) => {
            if (item.id === profile.id) {
                isCriteria = true;
            }
        });

        if (!isCriteria) {
            this.addCriteria(profile);
        } else {
            this.removeCriteria(profile);
        }
    }
    
    setLetter = (letter) => {
        if (letter === this.state.letter) {
            this.setState({ searched: false, letter: false });
            window.sessionStorage.removeItem('networkLetter');
        } else {
            this.setState({ letter });
            this.search(letter);

            window.sessionStorage.setItem('networkLetter', letter);
        }
    }

    onCriteriaChange = criteria => {
        this.criteria = criteria;

        if (this.criteria.length > 0) {
            this.search();
            this.setState({ letter: false });
        } else {
            this.setState({ searched: false, selectableIds: false });
        }

        window.sessionStorage.setItem('networkCriteria', JSON.stringify(this.criteria));
    }

    search(letter = false) {
        const ids = this.criteria
            .filter(criteria => criteria.id)
            .map(criteria => criteria.id)
            .join(",");
        
        const query = { resource_type: "user", relationship_ids: ids, and_join: '1', search_at_start: true, include_relationships: '1' };

        const userTitle = this.criteria.find(criteria => criteria.profileType.title === "User name contains the following phrase");

        if (letter) {
            query['query'] = letter;
            query['search_properties'] = 'lastName';
        } else if (userTitle) {
            query['query'] = userTitle.title;
            query['search_properties'] = 'firstName,lastName';
        }

        this.setState({ loading: true, searched: true });

        Api.search(query)
            .then(({ data }) => {
                let selectableIds = [];

                this.criteria.forEach(criteria => {
                    selectableIds.push(criteria.id);
                })

                data.forEach(item => {
                    if (item.relationships) {
                        item.relationships.forEach(id => {
                            selectableIds.push(id);
                        })
                    }
                })
                this.setState({ loading: false, users: data, selectableIds, });
            })
            .catch(err => console.error(err))
        ;
    }

    render() {
        let pageContent;

        if (this.state.searched === false && this.state.loading === false) {
            pageContent = (
                <>
                    <NetworkLetterList setLetter={this.setLetter} activeLetter={this.state.letter} />
                    {this.state.collaborators.length > 0 &&
                        <NetworkUserDetailList 
                            items={this.state.collaborators} 
                            title="Your Collaborations"
                            titleHref="/account/collaborators"
                        />
                    }

                    {this.state.institutions.length > 0 &&
                        <NetworkUserDetailList 
                            items={this.state.institutions} 
                            title="Your Institutions"
                            titleHref="/account/institutions"
                        />
                    }

                    {this.state.newUsers.length > 0 &&
                        <div className="users-list">
                            <h3>New Users</h3>
                            <Row>
                                {this.state.newUsers.map(user => (
                                    <NetworkUserItem key={user.id} user={user} />
                                ))}
                            </Row>
                        </div>
                    }
                </>
            );
        } else {
            pageContent = (
                <>
                    {this.criteria.length === 0 &&
                        <NetworkLetterList setLetter={this.setLetter} activeLetter={this.state.letter} />
                    }
                    
                    <div className="users-list">
                        {this.state.users.length === 0 && this.state.loading === false &&
                            <p>No Results Found.</p>
                        }

                        {this.state.users.length > 0 && 
                            <Row>
                                {this.state.users.map(user => (
                                    <NetworkUserItem key={user.id} user={user} />
                                ))}
                            </Row>   
                        }
                    </div> 
                </>
            );
        }
        
        return (
            <div className={"network-filter-page" + (this.state.loading ? ' is-loading' : '' )}>
                <FilterPage 
                    user={this.props.user} 
                    onMenuOpen={this.props.onMenuOpen}
                    pageTitle="Network"
                    loading={this.state.loading}
                    pageContent={pageContent}
                    onCriteriaChange={this.onCriteriaChange}
                    criteria={this.criteria}
                    removeCriteria={this.removeCriteria}
                    clearCriteria={this.clearCriteria}
                    searchInputRef={this.searchInputRef}
                    basket={false}
                    categories={false}
                    onProfileClick={this.onProfileClick}
                    isResourceIndex={false}
                    isDataIndex={false}
                    isNetworkIndex={true}
                    selectableIds={this.state.selectableIds}
                    titleSearchTitle="User name"
                    searchType="network"
                    dataEnabled={true}
                />
            </div>
        );
    }
}

export default NetworkFilterPage;