import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowUp, faArrowDown } from "@fortawesome/pro-solid-svg-icons"

const rawData = fetch("/json-data/countries-with-continent.json").then(async res => {
    const data = await res.json();
    countries = data
        .sort((a, b) => a.Country_Name.localeCompare(b.Country_Name))
        .map(country => {
            country.Continent_Name = country.Continent_Name.toLowerCase().replaceAll(" ", "-");
            country.Country_Name = country.Country_Name.replaceAll(/,.*/g, "");
            country.Url = country.Country_Name.toLowerCase().replaceAll(" ", "-").replaceAll("&", "and");
            return country;
        });
});
let countries;

const CountryMenu = props => {
    let [continent, setContinent] = useState(null);
    let [page, setPage] = useState(0);

    useEffect(() => {
        let parts = props.location.pathname.split("/");
        setContinent(parts[2]);
        setPage(0);
    }, [props.location.pathname]);

    if (!countries) {
        return "Loading";
    }

    let countryList = [];

    for (let country of countries) {
        if (country.Continent_Name === continent) {
            countryList.push(country);
        }
    }

    let items = props.items || 5;
    let pages = Math.ceil(countryList.length / items);

    let diff = pages * items - countryList.length;
    for (let i = 0; i < diff; ++i) {
        countryList.push(null);
    }

    let toRender = countryList.slice(page * items, (page + 1) * items);
    let canIncrement = page < pages - 1;
    let canDecrement = page > 0;
    let incrementPage = () => {
        if (canIncrement) {
            setPage(page + 1);
        }
    };

    let decrementPage = () => {
        if (canDecrement) {
            setPage(page - 1);
        }
    };

    return (
        <div className="country-picker-wrap">
            <h5 className="text-primary">Locations</h5>
            {toRender.map((country, index) => {
                    if (country === null) {
                        return (
                        <Button
                            className="p-4 my-2 invisible"
                            block
                            key={index}
                        >
                            Hidden
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    );
                }

                return (
                    <Button
                        as={Link}
                        to={`/profile/${country.Url}/`}
                        variant="dark"
                        className="p-4 my-2 d-flex justify-content-between align-items-center"
                        key={index}
                        block
                    >
                        {country.Country_Name}
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                );
            })}
            <div>
                <Button
                    variant="outline-primary"
                    disabled={!canDecrement}
                    className="m-1 rounded-circle"
                    onClick={decrementPage}
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </Button>
                <Button
                    variant="outline-primary"
                    disabled={!canIncrement}
                    className="m-1 rounded-circle"
                    onClick={incrementPage}
                >
                    <FontAwesomeIcon icon={faArrowDown} />
                </Button>
            </div>
        </div>
    );

};

export default withRouter(CountryMenu);
