class Drawer {
    constructor(gl, vertShaderSrc, fragShaderSrc) {
        this.gl = gl;

        this.vertShaderSrc = vertShaderSrc;
        this.fragShaderSrc = fragShaderSrc;

        this.vertShader = this._createShader(vertShaderSrc, gl.VERTEX_SHADER);
        this.fragShader = this._createShader(fragShaderSrc, gl.FRAGMENT_SHADER);

        this.program = this._createProgram();

        this.attribs = this._getAttributes();
        this.uniforms = this._getUniforms();

        this.primitives = 0;
    }

    setAttribute(name, buffer) {
        let attrib = this.attribs[name];

        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);
        this.gl.enableVertexAttribArray(attrib.location);
        this.gl.vertexAttribPointer(attrib.location, attrib.size, this.gl.FLOAT, false, 0, 0);
    }

    setUniform(name, value) {
        let uniform = this.uniforms[name];

        switch (uniform.type) {
            case "float":
                this.gl.uniform1f(uniform.location, value);
                break;

            case "vec3":
                this.gl.uniform3fv(uniform.location, value);
                break;

            case "mat4":
                this.gl.uniformMatrix4fv(uniform.location, false, value);
                break;

            default:
                throw new Error(`Uniform type '${uniform.type}' not implemented yet.`);
        }
    }

    createBuffer() {
        return this.gl.createBuffer();
    }

    deleteBuffer(b) {
        this.gl.deleteBuffer(b);
    }

    bufferData(buffer, data) {
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);

        this.gl.bufferData(
            this.gl.ARRAY_BUFFER,
            new Float32Array(data),
            this.gl.STATIC_DRAW
        );
    }

    use() {
        this.gl.useProgram(this.program);
    }

    setPrimitives(count) {
        this.primitives = count;
    }

    draw(mode, clear=true) {
        this.use();

        let gl = this.gl;

        if (clear) {
            gl.clear(gl.COLOR_BUFFER_BIT);
        }

        gl.drawArrays(gl[mode], 0, this.primitives);

        this._checkError();
    }

    destroy() {
        this.gl.deleteProgram(this.program);
        this.gl.deleteShader(this.vertShader);
        this.gl.deleteShader(this.fragShader);
    }

    _createShader(src, type) {
        let shader = this.gl.createShader(type);

        this.gl.shaderSource(shader, src);
        this.gl.compileShader(shader);

        this._checkError();

        return shader;
    }

    _createProgram() {
        let prog = this.gl.createProgram();

        this.gl.attachShader(prog, this.vertShader);
        this.gl.attachShader(prog, this.fragShader);
        this.gl.linkProgram(prog);

        this._checkError();

        return prog;
    }

    _getAttributes() {
        let attribs = {};
        let regex = /attribute\s+(\w+)\s+(\w+)\s*;/g;
        let source = this.vertShaderSrc;

        let match = regex.exec(source);
        while (match) {
            let [ , type, name ] = match;

            let size = /\d+/.exec(type);

            attribs[name] = {
                name: name,
                type: type,
                size: (size && size[0]) || 1,
                location: this.gl.getAttribLocation(this.program, name)
            };

            match = regex.exec(source);
        }

        return attribs;
    }

    _getUniforms() {
        let uniforms = {};
        let regex = /uniform\s+(\w+)\s+(\w+)\s*;/g;
        let source = this.vertShaderSrc + "\n;\n" + this.fragShaderSrc;

        let match;
        while ((match = regex.exec(source)) !== null) {
            let [ , type, name ] = match;

            let size = /\d+/.exec(type);
            uniforms[name] = {
                name: name,
                type: type,
                size: +(size && size[0]) || 1,
                location: this.gl.getUniformLocation(this.program, name)
            };
        }

        return uniforms;
    }

   _checkError() {
       let error = this.gl.getError();

       if (error !== this.gl.NO_ERROR) {
           console.error(error);
           throw new Error(error);
       }
   }
}

export default Drawer;
