import React from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import MessageUserPopUp from './MessageUserPopUp';

class MessageUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShow: false,
        }
    }

    handleModalShow = () => {
        this.setState({ modalShow: true });
    }

    handleModalClose = () => {
        this.setState({ modalShow: false });
    }

    render() {
        return (
            <>
                <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                    variant="ternary"
                    size="sm"
                    block
                    onClick={() => this.handleModalShow()}
                >
                    Send Message
                </Button>
                <MessageUserPopUp user={this.props.user} show={this.state.modalShow} close={this.handleModalClose} />
            </>
        );
    }
}

export default MessageUser;