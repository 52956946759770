import React, { useEffect, useState } from "react";
import axios from "axios";
import { GroupTable } from "./Moderator/GroupTable";
import { Paper } from "@material-ui/core";
const ModerateGroups = ({ user }) => {
    const [state, setState] = useState({
        loading: false,
        error: null,
        entryPoint: process.env.REACT_APP_API_ENTRYPOINT,
        moderatedGroups: [],
    });

    const getSelectedGroups = (data) => {
        const uniqueGroupIds = new Set();
        const selectedGroups = []; // Array to hold the final selected groups

        // // Recursive function to find children
        const findChildren = (parentId) => {
            data.forEach((item) => {
                const id = parseInt(item["@id"].split("/").pop());
                const itemParentId = item.parentGroup
                    ? parseInt(item.parentGroup["@id"].split("/").pop())
                    : null;

                if (itemParentId === parentId && !uniqueGroupIds.has(id)) {
                    uniqueGroupIds.add(id);
                    selectedGroups.push(item);
                    findChildren(id);
                }
            });
        };
        data.filter((item) => {
            const id = parseInt(item["@id"].split("/").pop());
            const parentId = item.parentGroup
                ? parseInt(item.parentGroup["@id"].split("/").pop())
                : null;

            const isModerated =
                user?.moderatingGroups.includes(id) ||
                user?.moderatingGroups.includes(parentId);

            if (isModerated && !uniqueGroupIds.has(id)) {
                selectedGroups.push(item);
                uniqueGroupIds.add(id);
                findChildren(id);
            }
        });
        return selectedGroups;
    };

    // Fetch data from API
    const fetchGroups = async () => {
        setState((prevState) => ({ ...prevState, loading: true, error: null }));

        try {
            const response = await axios.get(
                `${state.entryPoint}/api/user_groups`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );

            const data = response.data["hydra:member"] || [];
            const moderatedGroups = getSelectedGroups(data);

            setState((prevState) => ({
                ...prevState,
                moderatedGroups,
                loading: false,
                error: null,
            }));
        } catch (error) {
            console.error("Error fetching groups:", error);
            setState({
                loading: false,
                error: "Failed to load data.",
            });
        }
    };

    useEffect(() => {
        fetchGroups();
    }, []);

    const { loading, error, moderatedGroups, searchQuery, page, rowsPerPage } =
        state;

    return (
        <div style={{ width: "100%", overflow: "hidden" }}>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div>
                {moderatedGroups.map((group, index) => (
                    <div key={"group-key-" + index} className="mb-5">
                        <div>
                            <h3>{group.name}</h3>
                            <GroupTable
                                headers={["Assigned Users"]}
                                data={group.usersInGroups}
                                fetchGroups={fetchGroups}
                                groupId={group["@id"]}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ModerateGroups;

