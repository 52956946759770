import React from 'react'
import { Link} from "react-router-dom";
import { Button } from "react-bootstrap";
import Api from "../../Api";
import BackToAccount from './BackToAccount';
import InstitutionList from './InstitutionList';
import InstitutionAdd from './InstitutionAdd';
import Loading from '../Loading';

class Institutions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            assignedInstitutions: false,
            addableInstitutions: [],
            loading: true,
            toDelete: [],
        }
    }

    componentDidMount() {
        this.loadInstitutions();
    }

    manageDelete = (id) => {
        let toDelete = this.state.toDelete.slice(0);
        let index = toDelete.indexOf(id);

        if (index === -1) {
            toDelete.push(id);
            this.setState({ toDelete: toDelete })
        } else {
            toDelete.splice(index, 1);
            this.setState({ toDelete: toDelete })
        }
    }

    handleDelete = () => {
        Api.deleteRelationships(this.props.user.id, this.state.toDelete)
            .then(response => {
                this.loadInstitutions();
                this.setState({ toDelete: [] })
            })
        ;
    }

    loadInstitutions = () => {
        Api.search({ resource_type: "profile", profile_type: 5, relationship_ids: this.props.user.id }, true, true)
            .then(response => {
                let institutions = response.data;
                this.setState({ assignedInstitutions: institutions, loading: false });

                let institutionIds = [];

                institutions.forEach((institution, index) => {
                    institutionIds.push(institution.id);
                })

                Api.profiles({ 'page': 1, 'perPage': 1000, 'noRelationships': 1 }, true, true, 5)
                    .then((response) => {
                        let selectOptions = [];

                        response.forEach((institution, index) => {
                            if (!institutionIds.includes(institution.id)) {
                                selectOptions.push({ value: institution.id.toString(), name: institution.title });
                            }
                        })

                        this.setState({ addableInstitutions: selectOptions });
                    })
                ;
            })
        ;
    }

    render() {
        return (
            <div className="institutions">
                {this.state.loading && <Loading size="2x" />}

                {this.state.loading === false && 
                    <>
                        <InstitutionList 
                            user={this.props.user} 
                            institutions={this.state.assignedInstitutions} 
                            toDelete={this.state.toDelete} 
                            manageDelete={this.manageDelete} 
                            handleDelete={this.handleDelete}
                        />

                        <InstitutionAdd 
                            user={this.props.user} 
                            options={this.state.addableInstitutions}
                            reload={this.loadInstitutions} 
                        />
                        
                        <div className="institutions__create">
                            <Button as={Link} to={'/account/create-institution'}>Create New Institution</Button>
                        </div>

                        <BackToAccount />
                    </>
                }
            </div>
        );
    }
}

export default Institutions;