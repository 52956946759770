import React from 'react';
import { Button } from "react-bootstrap";
import InstitutionListItem from './InstitutionListItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

const InstitutionList = (props) => {
    return (
        
        <div className="institutions__list">
            <h3>Your Institutions</h3>
            {props.institutions.length === 0 &&
                <p>You're not assigned to any institutions. You can add any institutions you belong too below.</p>
            }
            {props.institutions.length > 0 && 
                <>
                    {props.toDelete.length > 0 && 
                        <Button className="institutions__delete-btn" variant="danger" onClick={props.handleDelete}>
                            <FontAwesomeIcon icon={faTrash} /> Confirm Deletion
                        </Button>
                    }

                    <p>You are currently assigned to the following institutions:</p>
                    {props.institutions.map((institution, index) => (
                        <InstitutionListItem 
                            key={index} 
                            institution={institution} 
                            manageDelete={props.manageDelete} 
                            toDelete={props.toDelete} 
                        />
                    ))}
                </>
            }
        </div>
    );
}

export default InstitutionList;