import React from 'react';
import PopUp from '../PopUp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faCartArrowDown } from "@fortawesome/pro-solid-svg-icons";

class ResourcesBasketPopUp extends React.Component {

    removeBasketItem = (resource) => {
        this.props.onRemoveFromBasket(resource);

        if ((this.props.basket.length - 1) === 0) {
            this.props.close()
        }
    }

    render() {
        if (!this.props.basket) {
            return null;
        }

        let basketIds = "";

        this.props.basket.forEach(item => {
            basketIds += item.id + ',';
        })

        let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;
        let downloadUrl = entryPoint + "/resources/serve-files?basketIds=" + basketIds;
        
        let modalContent = (
            <div>
                <div className="basket-modal-header">
                    <h3>Files Ready To Download</h3>
                    <a href={downloadUrl} target="_blank" className="filter-page-header-btn">
                        <span>
                            <FontAwesomeIcon icon={faCartArrowDown} />
                        </span>
                        
                        Download Resources ({this.props.basket.length})
                    </a>
                </div>
                
                <div className="basket-items">
                    {this.props.basket.map(resource => (
                        <div key={resource.id} className="basket-item">
                            <div className="basket-item__title">
                                <FontAwesomeIcon icon={faFile} />
                                {resource.title}
                            </div>
                            <div className="basket-item__remove" onClick={() => this.removeBasketItem(resource)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );

        return (
            <PopUp size="lg" className="basket-modal" content={modalContent} open={this.props.show} close={this.props.close} />
        );
    }
}

export default ResourcesBasketPopUp;