import React from 'react';
import Api from '../../Api';
import BackToAccount from './BackToAccount';
import Notification from './Notification';

class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: false,
        }
    }

    componentDidMount() {
        this.getNotifications();
    }

    getNotifications = (refresh = false) => {
        Api.userNotifications()
            .then(response => {
                this.setState({ notifications: response.notifications });
            })
        ;

        if (refresh) {
            this.props.loginRefresh();
        }
    }

    render() {
        if (this.state.notificaions && this.state.notifications.length === 0) {
            return <p>You have no notifications.</p>;
        }

        return (
            <div className="notifications">
                <div className="notifications__header">
                    You have <span>{this.props.user.unreadNotificationsCount}</span> unread notification{this.props.user.unreadNotificationsCount !== 1 ? 's' : '' }
                </div>

                {this.state.notifications.length > 0 &&
                    <div className="notifications__list">
                        {this.state.notifications.map((notification, index) => (
                            <Notification key={index} notification={notification} refresh={this.getNotifications} />
                        ))}
                    </div>
                }

                <BackToAccount />
            </div>
            
        );
    }
}

export default Notifications;