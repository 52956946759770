import React from 'react';
import Api from "../../Api";
import Loading from "../Loading";

class DataIndex extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            profileTypes: false,
            open: false,
            profiles: [],
            profileLoading: false,
            fetched: [],
        }
    }

    componentDidMount() {
        Api.profileTypes(false, true)
            .then(profileTypes => {
                this.setState({ profileTypes, loading: false });
            })
        ;
    }

    toggleProfileType = (profileTypeId) => {
        let open = (profileTypeId === this.state.open ? false : profileTypeId);

        this.setState({ open, profileLoading: true });

        let fetched = this.state.fetched;

        if (!fetched.includes(profileTypeId)) {
            Api.profiles({ 'order[title]': 'ASC' }, true, true, profileTypeId)
                .then(response => {
                    let profiles = this.state.profiles;
                    profiles[profileTypeId] = response;

                    fetched.push(profileTypeId);

                    this.setState({ profiles, fetched });
                })
            ;
        }
    }

    render() {
        let criteriaIds = [];

        this.props.criteria.forEach((item, index) => {
            criteriaIds.push(item.id);
        });

        return (
            <>
                <p>
                    <span className="mobile-filter-close" onClick={() => this.props.close()}>Close</span>
                </p>
                <h3>Filter</h3>
                <p>
                    Below are a list of the profile types allocated in the system. Clicking on one will display all the profiles within,
                    allowing you to select it and add it to your search filter on the right.
                </p>

                {this.state.loading &&
                    <Loading size="2x" />
                }

                {this.state.profileTypes && this.state.loading === false && 
                    <div className="data-index-profile-types-wrap">
                        {this.state.profileTypes.map((profileType, index) => (
                            <div key={index} className={"data-index-profile-type " + (this.state.open === profileType.id ? 'data-index-open' : '' ) }>
                                <h4 onClick={() => this.toggleProfileType(profileType.id)}>{profileType.title}</h4>
                                {this.state.profiles[profileType.id] && this.state.profiles[profileType.id].length &&
                                    <div className="data-index-profile-wrap">

                                        {this.state.profiles[profileType.id].map((profile, index) => (
                                            <div key={index} className={"data-index-profile " + (criteriaIds.includes(profile.id) ? 'is-criteria' : '' )} onClick={() => this.props.onClick(profile)}>{profile.title}</div>
                                        ))}
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                }
            </>
        );
    }
}

export default DataIndex;