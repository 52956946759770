import React from 'react';

const ForumLoginRedirect = props => {
    /**
     * If we've got this far, it means the user is logged in to Vector Hub.
     * Redirect them to the api route which will log them in to the forum
     */
    let forumLoginUrl = process.env.REACT_APP_FORUM_LINK + '/gvh-login.php?email=' + props.user.email;
    window.location.href = forumLoginUrl;

    return null
}

export default ForumLoginRedirect;