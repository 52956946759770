import React from 'react';

class DataMapKey extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: [],
        }
    }

    componentDidUpdate(prevProps) {
        // reset the disabled keys if the map type changes
        if (prevProps.mapType !== this.props.mapType) {
            this.setState({ disabled: [] })
        }
    }

    keyOnClick = (index, item) => {
        if (item.filter) {
            this.props.onClick(index);

            let disabled = this.state.disabled

            if (this.state.disabled.includes(index)) {
                disabled = disabled.filter(item => item !== index);
            } else {
                disabled.push(index);
            }

            this.setState({ disabled });
        }
    }

    render() {
        if (!this.props.markerColours || !this.props.markerColours[this.props.mapType]) {
            return null;
        }

        return (
            <div className="data-map-key">
                <h4>Key</h4>
                <div>
                    {Object.entries(this.props.markerColours[this.props.mapType]).map(([index, item]) => (
                        <div 
                            className={"marker-key-icon-wrap" + (this.state.disabled.includes(index) ? ' disabled' : '' ) + (item.filter !== true ? ' no-filter' : '' )} 
                            key={index} 
                            onClick={() => this.keyOnClick(index, item)}
                        >
                            <span className="marker-key-icon" style={{ background: item.secondaryColor, border: '2px solid ' + (item.primaryColor ? item.primaryColor : '#fff' ) }}></span> {index}
                        </div>
                    ))}
                </div>
            </div>  
        );
    }
}

export default DataMapKey;