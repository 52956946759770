import React from 'react';
import MenuLink from "./../MenuLink";
import ProfileLink from "./../ProfileLink";
import LanguageSelect from "./../LanguageSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const FilterPageMenu = props => {
    const history = useHistory();
    
    const closeMenu = () => {
        props.onClose();
        history.goBack();
    }

    return <div className="filter-page-menu">
        <div className="filter-page-menu-top">
            <div className="menu-button-wrap">
                {props.isMenuOpen === true ?
                    <Button
                        variant=""
                        onClick={() => closeMenu()}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button> :
                    <MenuLink onClick={props.onMenuOpen} />
                }
            </div>
            <div className="user-button-wrap">
                <ProfileLink />
            </div>
            <div className="filter-button-wrap" onClick={() => props.toggleFilterMenu()}>
                <FontAwesomeIcon icon={faSearch} />
            </div>
        </div>
        <div className="filter-page-menu-bottom">
            <LanguageSelect user={props.user} />
        </div>
    </div>
}

export default FilterPageMenu;