import React from 'react';
import Api from "../../Api";

class DataCommonSearches extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            commonSearches: false
        }
    }

    componentDidMount() {
        Api.profiles({ 'order[dataSearchCount]': 'desc', noRelationships: 1 }, true, true)
            .then(response => {
                this.setState({ commonSearches: response.slice(0, 4) });
            })
        ;
    }

    handleClick = (searchTerm) => {
        this.props.addCriteria(searchTerm);
    }

    render() {
        if (!this.state.commonSearches) {
            return null;
        }

        return (
            <div className="data-common-searches mb-4">
                <h4 className="mb-4">Common searches</h4>
                <ul className="common-search-list list-inline">
                    {this.state.commonSearches.map((item, index) => (
                        <li key={index} className="px-0 mx-0 mb-2" onClick={() => this.handleClick(item)}>
                            <span className="text-primary">{item.profileType.title}:</span> 
                            <em> {item.title}</em>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default DataCommonSearches;