import React from 'react';
import { Alert, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import BackToAccount from './BackToAccount';

class UpdatePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            password_confirm: '',
            passwordRequirements: false,
            loading: false,
            errorMessage: '',
            resetEndpoint: '/network/profile/update-password'
        }

        this.checkPassword = this.checkPassword.bind(this);
        this.id = false;
    }

    componentDidMount() {
        if (this.props.reset === true) {
            let match = this.props.location.pathname.match(/\/reset-password\/(.*)/);
        
            if (match) {
                this.id = match[1];
                this.setState({ resetEndpoint: '/network/update-password' })
            } else {
                return;
            }
        }
    }

    checkPassword() {
        let errorString = '';

        if (this.state.password.length < 8) {
            errorString += 'Must be 8 characters or more. ';
        }

        let uppercase = this.state.password.replace(/[^A-Z]/g, "").length;
        if (!uppercase) {
            errorString += 'Must contain an uppercase character. ';
        }

        let lowercase = this.state.password.replace(/[^a-z]/g, "").length;
        if (!lowercase) {
            errorString += 'Must contain a lowercase character. ';
        }

        let numbers = this.state.password.replace(/[^0-9]/g, "").length;
        if (!numbers) {
            errorString += 'Must contain a number. ';
        }

        let specialChars = this.state.password.replace(/[^!@#$%^&*(),.?":{}|<>]/g, "").length;
        if (!specialChars) {
            errorString += 'Must contain a special character. ';
        }

        this.setState({ passwordRequirements: errorString, errorMessage: errorString });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const fieldValue = target.value;
        const fieldName = target.name;

        this.setState({
            [fieldName]: fieldValue
        });
    }

    handleSubmit = (event) => {
        this.setState({ loading: true, success: null });

        const thisForm = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        thisForm.className += " was-validated";

        this.checkPassword();

        if (this.state.password === this.state.password_confirm) {
            if (thisForm.checkValidity() !== false) {
                const FormData = require('form-data');
                const form = new FormData();
                
                form.append('password', this.state.password);
                form.append('password_confirm', this.state.password_confirm);

                if (this.props.reset === true && this.id) {
                    form.append('id', this.id);
                }

                let entryPoint = process.env.REACT_APP_API_ENTRYPOINT;

                axios.post(entryPoint + this.state.resetEndpoint, form, {
                    headers: {
                        authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                })
                    .then(response => {
                        if (response.data.success) {
                            this.setState({ success: true, password: '', password_confirm: '', loading: false });
                        }

                        if (response.data.success === false) {
                            this.setState({ success: false, errorMessage: response.data.message, loading: false });
                        }

                        thisForm.className = "network-create-account-form my-auto";
                    })
                    .catch(error => {
                        console.log(error);
                    })
                ;
            } else {
                this.setState({ loading: false })
            }
        } else {
            this.setState({ success: false, errorMessage: 'The passwords do not match', loading: false });
        }
    }

    render() {
        return (
            <>
                {this.state.success === true &&
                    <Alert className="mb-4" variant="success" onClose={() => (this.setState({success: null}))}>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>
                            Password updated successfully. {this.props.reset && 
                                <>
                                    You can now <Link to="/log-in">log in</Link>.
                                </>
                            }
                        </p>
                    </Alert>
                }

                {this.state.success === false &&
                    <Alert className="mb-4" variant="danger" onClose={() => (this.setState({success: null}))} dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{this.state.errorMessage}</p>
                    </Alert>
                }

                <Form noValidate className="network-create-account-form my-auto needs-validation" onSubmit={this.handleSubmit} autoComplete="off" id="register-form">
                    <Form.Group controlId="update-password-password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            pattern="^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=!?]).*$" 
                            name="password" 
                            size="lg" 
                            type="password" 
                            value={this.state.password} 
                            onChange={this.handleInputChange} 
                            required 
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.passwordRequirements}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="update-password-password">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control 
                            pattern="^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=!?]).*$" 
                            name="password_confirm" 
                            size="lg" 
                            type="password" 
                            value={this.state.password_confirm} 
                            onChange={this.handleInputChange} 
                            required 
                        />
                    </Form.Group>

                    <Button className="mx-0 mt-5 mb-4 font-smooth text-white"
                        variant="ternary"
                        size="lg"
                        type="submit"
                        block>
                            {this.state.loading === true && 
                                <FontAwesomeIcon icon={faCircleNotch} size="6x" color="#FF7442" spin />
                            }
                            {this.state.loading === false && 
                                <>Update Password</>
                            }
                    </Button>

                    <BackToAccount />
                </Form>
            </>  
        );
    }
}

export default withRouter(UpdatePassword);
